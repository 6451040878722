import React from 'react';

const Background = () => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 288 288" xmlSpace="preserve" fill="currentColor">
<g>
	<g>
		<path d="M130,177.3C130,177.3,130,177.3,130,177.3C130,177.2,130,177.2,130,177.3C130,177.2,130,177.2,130,177.3
			C130,177.3,130,177.3,130,177.3z"/>
        <path d="M110.5,163.3C110.5,163.3,110.5,163.3,110.5,163.3c0.1-0.1,0-0.1,0-0.1S110.4,163.2,110.5,163.3
			C110.4,163.3,110.4,163.3,110.5,163.3z"/>
        <path d="M201.6,170.7C201.6,170.7,201.6,170.7,201.6,170.7C201.6,170.7,201.6,170.7,201.6,170.7
			C201.6,170.7,201.5,170.7,201.6,170.7C201.5,170.7,201.6,170.7,201.6,170.7z"/>
        <path d="M197,174.7C197,174.7,197,174.7,197,174.7C197,174.7,197,174.6,197,174.7C197,174.6,197,174.7,197,174.7
			C197,174.7,197,174.7,197,174.7z"/>
        <path d="M192.1,178.2C192.1,178.2,192.1,178.2,192.1,178.2C192.1,178.2,192.1,178.2,192.1,178.2
			C192.1,178.2,192.1,178.2,192.1,178.2C192.1,178.2,192.1,178.2,192.1,178.2z"/>
        <path d="M186.9,181.3C186.9,181.3,186.9,181.3,186.9,181.3C186.9,181.3,186.9,181.3,186.9,181.3
			C186.9,181.3,186.9,181.3,186.9,181.3C186.9,181.3,186.9,181.3,186.9,181.3z"/>
        <path d="M181.5,184C181.5,184,181.6,184,181.5,184c0.1-0.1,0.1-0.2,0-0.2C181.4,183.9,181.4,183.9,181.5,184
			C181.4,184,181.4,184,181.5,184z"/>
        <path d="M175.8,186.1C175.8,186.1,175.9,186.1,175.8,186.1c0.1-0.1,0-0.1,0-0.1C175.8,186,175.7,186,175.8,186.1
			C175.7,186.1,175.8,186.1,175.8,186.1z"/>
        <path d="M170,187.8C170,187.8,170,187.7,170,187.8c0.1-0.1,0-0.1,0-0.1C169.9,187.6,169.9,187.6,170,187.8
			C169.9,187.7,169.9,187.8,170,187.8z"/>
        <circle cx="164" cy="188.8" r="0.1"/>
        <path d="M158,189.4C158,189.4,158.1,189.3,158,189.4c0.1-0.1,0-0.1,0-0.1C157.9,189.2,157.9,189.3,158,189.4
			C157.9,189.3,157.9,189.4,158,189.4z"/>
        <path d="M151.9,189.4C152,189.4,152,189.3,151.9,189.4c0.1-0.1,0-0.1,0-0.1C151.9,189.2,151.9,189.3,151.9,189.4
			C151.9,189.3,151.9,189.4,151.9,189.4z"/>
        <path d="M145.9,188.8C145.9,188.8,146,188.8,145.9,188.8c0.1-0.1,0-0.1,0-0.1C145.9,188.7,145.8,188.7,145.9,188.8
			C145.8,188.8,145.9,188.8,145.9,188.8z"/>
        <path d="M139.9,187.8C140,187.8,140,187.7,139.9,187.8c0.1-0.1,0-0.1,0-0.1C139.9,187.6,139.9,187.6,139.9,187.8
			C139.9,187.7,139.9,187.8,139.9,187.8z"/>
        <path d="M134.1,186.1C134.2,186.1,134.2,186.1,134.1,186.1c0.1-0.1,0-0.1,0-0.1C134.1,186,134,186,134.1,186.1
			C134,186.1,134.1,186.1,134.1,186.1z"/>
        <path d="M128.4,184.1c0.1,0,0.1-0.1,0.1-0.1s-0.1-0.1-0.1-0.1s-0.1,0.1-0.1,0.1S128.4,184.1,128.4,184.1z"/>
        <path d="M123,181.4C123,181.4,123.1,181.4,123,181.4c0.1-0.1,0-0.2,0-0.2C122.9,181.2,122.9,181.3,123,181.4
			C122.9,181.4,122.9,181.4,123,181.4z"/>
        <path
            d="M117.8,178.4c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C117.7,178.3,117.7,178.4,117.8,178.4z"
        />
        <path d="M112.9,174.7C112.9,174.7,113,174.7,112.9,174.7c0.1-0.1,0-0.1,0-0.1C112.9,174.6,112.8,174.6,112.9,174.7
			C112.8,174.7,112.9,174.7,112.9,174.7z"/>
        <path d="M108.3,170.8c0.1,0,0.2-0.1,0.2-0.2s-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2S108.3,170.8,108.3,170.8z"/>
        <path d="M104.2,166.5c0.1,0,0.2-0.1,0.2-0.2s-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2S104.1,166.5,104.2,166.5z"/>
        <path d="M100.4,161.6C100.4,161.6,100.5,161.6,100.4,161.6c0.1-0.1,0-0.1,0-0.1C100.4,161.5,100.3,161.5,100.4,161.6
			C100.3,161.6,100.4,161.6,100.4,161.6z"/>
        <path
            d="M97.1,156.7c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C96.9,156.6,97,156.7,97.1,156.7z"/>
        <path d="M94.2,151.3C94.2,151.3,94.3,151.2,94.2,151.3c0.1-0.1,0-0.1,0-0.1S94.1,151.2,94.2,151.3
			C94.1,151.2,94.2,151.3,94.2,151.3z"/>
        <path
            d="M91.8,145.8c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C91.7,145.7,91.7,145.8,91.8,145.8z"/>
        <path
            d="M89.9,139.9C90,139.9,90,139.9,89.9,139.9c0.1-0.1,0-0.1,0-0.1S89.9,139.8,89.9,139.9C89.9,139.9,89.9,139.9,89.9,139.9z"
        />
        <path d="M88.6,134C88.6,134,88.6,134,88.6,134C88.6,134,88.6,134,88.6,134C88.6,134,88.6,134,88.6,134C88.6,134,88.6,134,88.6,134
			z"/>
        <path d="M91.8,98.3C91.8,98.3,91.8,98.2,91.8,98.3C91.8,98.2,91.8,98.2,91.8,98.3C91.8,98.2,91.8,98.2,91.8,98.3
			C91.8,98.2,91.8,98.3,91.8,98.3z"/>
        <circle cx="229.2" cy="121.9" r="0"/>
        <path d="M228.9,128C228.9,128,228.9,128,228.9,128C228.9,128,228.9,128,228.9,128C228.9,128,228.9,128,228.9,128
			C228.9,128,228.9,128,228.9,128z"/>
        <path d="M228.2,134C228.2,134,228.2,134,228.2,134C228.2,134,228.2,134,228.2,134C228.2,134,228.1,134,228.2,134
			C228.1,134,228.2,134,228.2,134z"/>
        <path d="M226.9,139.9C227,139.9,227,139.9,226.9,139.9C227,139.9,227,139.9,226.9,139.9C226.9,139.9,226.9,139.9,226.9,139.9
			C226.9,139.9,226.9,139.9,226.9,139.9z"/>
        <path d="M225.2,145.7C225.2,145.7,225.3,145.7,225.2,145.7C225.3,145.7,225.2,145.7,225.2,145.7
			C225.2,145.7,225.2,145.7,225.2,145.7C225.2,145.7,225.2,145.7,225.2,145.7z"/>
        <path d="M223.1,151.4C223.1,151.4,223.1,151.4,223.1,151.4C223.1,151.4,223.1,151.3,223.1,151.4C223,151.3,223,151.4,223.1,151.4
			C223,151.4,223,151.4,223.1,151.4z"/>
        <path d="M220.4,156.8C220.4,156.8,220.5,156.8,220.4,156.8C220.5,156.8,220.4,156.8,220.4,156.8
			C220.4,156.8,220.4,156.8,220.4,156.8C220.4,156.8,220.4,156.8,220.4,156.8z"/>
        <path d="M217.4,162.1C217.4,162.1,217.4,162.1,217.4,162.1C217.4,162,217.4,162,217.4,162.1C217.4,162,217.4,162,217.4,162.1
			C217.4,162.1,217.4,162.1,217.4,162.1z"/>
        <path d="M213.9,167C213.9,167,213.9,167,213.9,167C213.9,167,213.9,167,213.9,167C213.9,167,213.9,167,213.9,167
			C213.9,167,213.9,167,213.9,167z"/>
        <path
            d="M210,171.8c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C209.9,171.7,210,171.8,210,171.8z"/>
        <path d="M205.8,176C205.8,176,205.8,176,205.8,176C205.8,176,205.8,176,205.8,176C205.8,176,205.8,176,205.8,176
			C205.8,176,205.8,176,205.8,176z"/>
        <path d="M201.2,180C201.3,180,201.3,180,201.2,180c0.1-0.1,0-0.2,0-0.2C201.2,179.8,201.1,179.9,201.2,180
			C201.1,180,201.2,180,201.2,180z"/>
        <path d="M196.3,183.6c0.1,0,0.1-0.1,0.1-0.1s-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1S196.3,183.6,196.3,183.6z"/>
        <path
            d="M191.2,186.8c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C191,186.8,191.1,186.8,191.2,186.8z"/>
        <path d="M185.8,189.5C185.8,189.5,185.9,189.5,185.8,189.5c0.1-0.1,0-0.2,0-0.2C185.7,189.3,185.7,189.4,185.8,189.5
			C185.7,189.5,185.7,189.5,185.8,189.5z"/>
        <path
            d="M180.2,191.9c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C180,191.8,180.1,191.9,180.2,191.9z"/>
        <path
            d="M174.4,193.8c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C174.2,193.7,174.3,193.8,174.4,193.8z"
        />
        <circle cx="168.5" cy="194.9" r="0.3"/>
        <path
            d="M162.5,195.9c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C162.3,195.8,162.4,195.9,162.5,195.9z"
        />
        <path
            d="M156.5,196.3c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C156.2,196.2,156.3,196.3,156.5,196.3z"
        />
        <circle cx="150.4" cy="196" r="0.2"/>
        <path
            d="M144.4,195.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C144.2,195.5,144.3,195.6,144.4,195.6z"
        />
        <path
            d="M138.4,194.6c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.2-0.3,0.3C138.1,194.4,138.3,194.6,138.4,194.6z"
        />
        <path
            d="M132.6,193.1c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C132.2,192.9,132.4,193.1,132.6,193.1z"
        />
        <path
            d="M126.9,191c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4C126.5,190.8,126.7,191,126.9,191z"/>
        <path
            d="M121.4,188.3c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C121.2,188.2,121.3,188.3,121.4,188.3z"
        />
        <path d="M116.1,185.4c0.2,0,0.3-0.1,0.3-0.3s-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3S116,185.4,116.1,185.4z"/>
        <path
            d="M111.1,182c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3C110.8,181.9,111,182,111.1,182z"/>
        <path
            d="M106.4,178.3c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C106.1,178.1,106.2,178.3,106.4,178.3z"
        />
        <path
            d="M102,174.1c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C101.7,174,101.8,174.1,102,174.1z"/>
        <path
            d="M97.9,169.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C97.7,169.5,97.8,169.6,97.9,169.6z"/>
        <path
            d="M94.2,164.8c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C94,164.7,94.1,164.8,94.2,164.8z"/>
        <path
            d="M91,159.7c0.1,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3C90.7,159.6,90.8,159.7,91,159.7z"/>
        <path
            d="M88.1,154.3c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C87.9,154.2,88,154.3,88.1,154.3z"/>
        <path
            d="M85.7,148.8c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2s-0.2,0.1-0.2,0.2C85.5,148.7,85.6,148.8,85.7,148.8z"/>
        <path d="M83.8,143c0.1,0,0.2-0.1,0.2-0.2s-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2S83.7,143,83.8,143z"/>
        <path
            d="M82.3,137.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C82.1,137,82.2,137.1,82.3,137.1z"/>
        <path d="M81.3,131.1c0.1,0,0.1-0.1,0.1-0.1s-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1S81.2,131.1,81.3,131.1z"/>
        <path
            d="M80.8,125C80.9,125,80.9,125,80.8,125c0.1-0.1,0-0.2,0-0.2C80.8,124.9,80.7,124.9,80.8,125C80.7,125,80.8,125,80.8,125z"
        />
        <path d="M80.8,119C80.9,119,80.9,118.9,80.8,119c0.1-0.1,0-0.1,0-0.1C80.8,118.8,80.8,118.9,80.8,119
			C80.8,118.9,80.8,119,80.8,119z"/>
        <path d="M81.3,112.9C81.3,112.9,81.3,112.9,81.3,112.9C81.3,112.9,81.3,112.8,81.3,112.9C81.3,112.8,81.3,112.9,81.3,112.9
			C81.3,112.9,81.3,112.9,81.3,112.9z"/>
        <path d="M82.3,106.9C82.3,106.9,82.3,106.9,82.3,106.9C82.3,106.9,82.3,106.9,82.3,106.9C82.3,106.9,82.3,106.9,82.3,106.9
			C82.3,106.9,82.3,106.9,82.3,106.9z"/>
        <path d="M83.8,101.1C83.8,101.1,83.8,101,83.8,101.1C83.8,101,83.8,101,83.8,101.1C83.8,101,83.7,101,83.8,101.1
			C83.7,101,83.8,101.1,83.8,101.1z"/>
        <path d="M85.7,95.3C85.7,95.3,85.7,95.3,85.7,95.3C85.7,95.3,85.7,95.3,85.7,95.3C85.7,95.3,85.7,95.3,85.7,95.3
			C85.7,95.3,85.7,95.3,85.7,95.3z"/>
        <path d="M97.9,74.5C97.9,74.5,97.9,74.5,97.9,74.5C97.9,74.5,97.9,74.5,97.9,74.5C97.9,74.5,97.9,74.5,97.9,74.5
			C97.9,74.5,97.9,74.5,97.9,74.5z"/>
        <path d="M228.2,109.9C228.2,109.9,228.2,109.9,228.2,109.9C228.2,109.9,228.2,109.9,228.2,109.9
			C228.2,109.9,228.1,109.9,228.2,109.9C228.1,109.9,228.2,109.9,228.2,109.9z"/>
        <path d="M228.9,115.9C228.9,115.9,228.9,115.9,228.9,115.9C228.9,115.9,228.9,115.9,228.9,115.9
			C228.9,115.9,228.9,115.9,228.9,115.9C228.9,115.9,228.9,115.9,228.9,115.9z"/>
        <path d="M235.7,128C235.7,128,235.7,128,235.7,128C235.7,128,235.7,128,235.7,128C235.7,128,235.6,128,235.7,128
			C235.6,128,235.7,128,235.7,128z"/>
        <path
            d="M235,134C235,134,235,134,235,134C235,134,235,134,235,134C235,134,235,134,235,134C235,134,235,134,235,134z"/>
        <path d="M233.9,140C233.9,140,233.9,140,233.9,140C233.9,139.9,233.9,139.9,233.9,140C233.9,139.9,233.8,139.9,233.9,140
			C233.8,140,233.9,140,233.9,140z"/>
        <path d="M232.3,145.8C232.3,145.8,232.3,145.8,232.3,145.8C232.3,145.8,232.3,145.8,232.3,145.8
			C232.3,145.8,232.3,145.8,232.3,145.8C232.3,145.8,232.3,145.8,232.3,145.8z"/>
        <path d="M230.3,151.5C230.3,151.5,230.3,151.5,230.3,151.5C230.3,151.5,230.3,151.5,230.3,151.5
			C230.3,151.5,230.3,151.5,230.3,151.5C230.3,151.5,230.3,151.5,230.3,151.5z"/>
        <path d="M227.9,157.1C227.9,157.1,227.9,157.1,227.9,157.1C227.9,157,227.9,157,227.9,157.1C227.9,157,227.9,157,227.9,157.1
			C227.9,157.1,227.9,157.1,227.9,157.1z"/>
        <path d="M225.1,162.4C225.1,162.4,225.1,162.4,225.1,162.4C225.1,162.4,225.1,162.4,225.1,162.4C225,162.4,225,162.4,225.1,162.4
			C225,162.4,225,162.4,225.1,162.4z"/>
        <path d="M221.8,167.6C221.9,167.6,221.9,167.6,221.8,167.6c0.1-0.1,0-0.1,0-0.1C221.8,167.5,221.8,167.5,221.8,167.6
			C221.8,167.6,221.8,167.6,221.8,167.6z"/>
        <path d="M218.2,172.5C218.3,172.5,218.3,172.4,218.2,172.5c0.1-0.1,0.1-0.2,0-0.2C218.2,172.3,218.1,172.3,218.2,172.5
			C218.1,172.4,218.2,172.5,218.2,172.5z"/>
        <path d="M214.3,177.1c0.1,0,0.2-0.1,0.2-0.2s-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2S214.2,177.1,214.3,177.1z"/>
        <path
            d="M210,181.4c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C209.9,181.3,209.9,181.4,210,181.4z"/>
        <path d="M205.4,185.3c0.1,0,0.1-0.1,0.1-0.1s-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1S205.3,185.3,205.4,185.3z"/>
        <path
            d="M200.6,188.9c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C200.4,188.9,200.5,188.9,200.6,188.9z"
        />
        <path
            d="M195.4,192.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C195,192.3,195.2,192.5,195.4,192.5z"/>
        <path
            d="M190.1,195.2c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.2-0.3,0.3C189.7,195,189.9,195.2,190.1,195.2z"/>
        <path d="M184.5,197.5c0.1,0,0.2-0.1,0.2-0.2s-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2S184.4,197.5,184.5,197.5z"/>
        <path
            d="M178.8,199.6c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C178.5,199.4,178.7,199.6,178.8,199.6z"
        />
        <path
            d="M173,201.2c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C172.6,201,172.8,201.2,173,201.2z"/>
        <path
            d="M167,202.3c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C166.7,202.2,166.8,202.3,167,202.3z"/>
        <path
            d="M161,202.9c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3C160.7,202.8,160.9,202.9,161,202.9z"/>
        <path
            d="M155,203.1c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C154.7,203,154.8,203.1,155,203.1z"/>
        <path
            d="M148.9,203c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C148.5,202.9,148.7,203,148.9,203z"/>
        <path
            d="M142.9,202.3c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.2-0.3,0.3C142.6,202.1,142.7,202.3,142.9,202.3z"
        />
        <path
            d="M136.9,201.3c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C136.5,201.1,136.7,201.3,136.9,201.3z"
        />
        <path d="M131.1,199.9c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6S130.8,199.9,131.1,199.9z"/>
        <path
            d="M125.4,197.8c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C124.8,197.6,125.1,197.8,125.4,197.8z"
        />
        <path
            d="M119.8,195.3c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C119.4,195.1,119.6,195.3,119.8,195.3z"
        />
        <path
            d="M114.5,192.6c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C113.9,192.3,114.2,192.6,114.5,192.6z"
        />
        <circle cx="109.4" cy="188.8" r="0.4"/>
        <path
            d="M104.5,185.7c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C104,185.5,104.2,185.7,104.5,185.7z"/>
        <path
            d="M99.9,181.7c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C99.5,181.5,99.7,181.7,99.9,181.7z"/>
        <path
            d="M95.6,177.3c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C95.3,177.2,95.4,177.3,95.6,177.3z"/>
        <path
            d="M91.7,172.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C91.5,172.5,91.6,172.6,91.7,172.6z"/>
        <path
            d="M88.1,167.8c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3C87.8,167.7,87.9,167.8,88.1,167.8z"/>
        <path
            d="M84.9,162.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C84.6,162.5,84.7,162.6,84.9,162.6z"/>
        <path
            d="M82,157.3c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C81.8,157.2,81.9,157.3,82,157.3z"/>
        <path d="M79.6,151.7c0.1,0,0.2-0.1,0.2-0.2s-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2S79.5,151.7,79.6,151.7z"/>
        <path
            d="M77.6,146c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C77.4,145.9,77.5,146,77.6,146z"/>
        <path
            d="M76.1,140.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C75.8,140.1,75.9,140.2,76.1,140.2z"/>
        <path
            d="M74.9,134.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C74.7,134.1,74.8,134.2,74.9,134.2z"/>
        <path d="M74.2,128.2c0.1,0,0.2-0.1,0.2-0.2s-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2S74.1,128.2,74.2,128.2z"/>
        <path
            d="M74,122.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C73.9,122,73.9,122.1,74,122.1z"/>
        <path d="M74.2,116C74.3,116,74.3,115.9,74.2,116c0.1-0.1,0.1-0.2,0-0.2C74.2,115.8,74.2,115.8,74.2,116
			C74.2,115.9,74.2,116,74.2,116z"/>
        <path d="M74.9,110C75,110,75,109.9,74.9,110c0.1-0.1,0.1-0.2,0-0.2C74.9,109.8,74.8,109.8,74.9,110C74.8,109.9,74.9,110,74.9,110z
			"/>
        <path
            d="M76.1,104C76.1,104,76.1,104,76.1,104c0.1-0.1,0-0.1,0-0.1C76,103.9,76,103.9,76.1,104C76,104,76,104,76.1,104z"/>
        <path d="M77.6,98.1C77.6,98.1,77.6,98.1,77.6,98.1C77.6,98.1,77.6,98.1,77.6,98.1C77.6,98.1,77.6,98.1,77.6,98.1
			C77.6,98.1,77.6,98.1,77.6,98.1z"/>
        <path d="M79.6,92.4C79.6,92.4,79.6,92.4,79.6,92.4C79.6,92.3,79.6,92.3,79.6,92.4C79.6,92.3,79.6,92.3,79.6,92.4
			C79.6,92.4,79.6,92.4,79.6,92.4z"/>
        <path
            d="M82,86.8C82,86.8,82.1,86.8,82,86.8C82.1,86.8,82,86.8,82,86.8C82,86.8,82,86.8,82,86.8C82,86.8,82,86.8,82,86.8z"/>
        <path d="M84.9,81.5C84.9,81.5,84.9,81.5,84.9,81.5C84.9,81.4,84.9,81.4,84.9,81.5C84.9,81.4,84.8,81.4,84.9,81.5
			C84.8,81.5,84.9,81.5,84.9,81.5z"/>
        <path d="M88.1,76.4C88.1,76.4,88.1,76.3,88.1,76.4C88.1,76.3,88.1,76.3,88.1,76.4C88.1,76.3,88.1,76.3,88.1,76.4
			C88.1,76.3,88.1,76.4,88.1,76.4z"/>
        <path d="M91.7,71.5C91.7,71.5,91.7,71.5,91.7,71.5C91.7,71.5,91.7,71.4,91.7,71.5C91.7,71.4,91.7,71.5,91.7,71.5
			C91.7,71.5,91.7,71.5,91.7,71.5z"/>
        <path d="M95.6,66.9C95.6,66.9,95.6,66.9,95.6,66.9C95.6,66.9,95.6,66.9,95.6,66.9C95.6,66.9,95.6,66.9,95.6,66.9
			C95.6,66.9,95.6,66.9,95.6,66.9z"/>
        <path d="M99.9,62.6C99.9,62.6,99.9,62.6,99.9,62.6C99.9,62.6,99.9,62.6,99.9,62.6C99.9,62.6,99.9,62.6,99.9,62.6
			C99.9,62.6,99.9,62.6,99.9,62.6z"/>
        <path d="M104.5,58.7C104.5,58.7,104.5,58.7,104.5,58.7C104.5,58.6,104.5,58.6,104.5,58.7C104.5,58.6,104.5,58.6,104.5,58.7
			C104.5,58.7,104.5,58.7,104.5,58.7z"/>
        <path d="M109.4,55.1C109.4,55.1,109.4,55.1,109.4,55.1C109.4,55,109.4,55,109.4,55.1C109.4,55,109.3,55,109.4,55.1
			C109.3,55.1,109.4,55.1,109.4,55.1z"/>
        <path d="M114.5,51.9C114.5,51.9,114.5,51.8,114.5,51.9C114.5,51.8,114.5,51.8,114.5,51.9C114.5,51.8,114.5,51.8,114.5,51.9
			C114.5,51.8,114.5,51.9,114.5,51.9z"/>
        <path d="M125.4,46.6C125.4,46.6,125.4,46.6,125.4,46.6C125.4,46.6,125.4,46.6,125.4,46.6C125.4,46.6,125.4,46.6,125.4,46.6
			C125.4,46.6,125.4,46.6,125.4,46.6z"/>
        <path d="M235,109.9C235,109.9,235,109.9,235,109.9C235,109.9,235,109.8,235,109.9C235,109.8,235,109.9,235,109.9
			C235,109.9,235,109.9,235,109.9z"/>
        <path d="M235.7,115.9C235.7,115.9,235.7,115.9,235.7,115.9C235.7,115.9,235.7,115.9,235.7,115.9
			C235.7,115.9,235.6,115.9,235.7,115.9C235.6,115.9,235.7,115.9,235.7,115.9z"/>
        <path d="M242.6,122C242.7,122,242.7,121.9,242.6,122C242.7,121.9,242.7,121.9,242.6,122C242.6,121.9,242.6,121.9,242.6,122
			C242.6,121.9,242.6,122,242.6,122z"/>
        <path d="M242.4,128C242.4,128,242.5,128,242.4,128C242.5,128,242.4,128,242.4,128C242.4,128,242.4,128,242.4,128
			C242.4,128,242.4,128,242.4,128z"/>
        <path d="M241.8,134C241.8,134,241.8,134,241.8,134C241.8,134,241.8,134,241.8,134C241.8,134,241.8,134,241.8,134
			C241.8,134,241.8,134,241.8,134z"/>
        <path d="M240.8,140C240.8,140,240.8,140,240.8,140c0.1-0.1,0-0.1,0-0.1C240.7,139.9,240.7,139.9,240.8,140
			C240.7,140,240.7,140,240.8,140z"/>
        <path d="M239.3,145.9C239.4,145.9,239.4,145.9,239.3,145.9c0.1-0.1,0-0.1,0-0.1C239.3,145.8,239.3,145.8,239.3,145.9
			C239.3,145.9,239.3,145.9,239.3,145.9z"/>
        <path d="M237.5,151.7C237.5,151.7,237.5,151.6,237.5,151.7c0.1-0.1,0-0.1,0-0.1C237.4,151.5,237.4,151.6,237.5,151.7
			C237.4,151.6,237.4,151.7,237.5,151.7z"/>
        <path d="M235.2,157.3C235.3,157.3,235.3,157.3,235.2,157.3c0.1-0.1,0-0.1,0-0.1C235.2,157.2,235.2,157.2,235.2,157.3
			C235.2,157.3,235.2,157.3,235.2,157.3z"/>
        <path d="M232.6,162.7C232.6,162.7,232.6,162.7,232.6,162.7C232.6,162.7,232.6,162.7,232.6,162.7
			C232.6,162.7,232.6,162.7,232.6,162.7C232.6,162.7,232.6,162.7,232.6,162.7z"/>
        <path d="M229.6,168C229.6,168,229.7,168,229.6,168c0.1-0.1,0-0.1,0-0.1C229.6,167.9,229.5,167.9,229.6,168
			C229.5,168,229.6,168,229.6,168z"/>
        <path
            d="M226.2,173.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1s-0.1,0.1-0.1,0.1C226.1,173.1,226.2,173.1,226.2,173.1z"/>
        <path
            d="M222.6,177.9c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C222.4,177.8,222.5,177.9,222.6,177.9z"
        />
        <path
            d="M218.5,182.5c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C218.4,182.4,218.5,182.5,218.5,182.5z"
        />
        <path
            d="M214.2,186.7c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C214.1,186.6,214.2,186.7,214.2,186.7z"
        />
        <path
            d="M209.6,190.8c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C209.3,190.6,209.5,190.8,209.6,190.8z"
        />
        <path
            d="M204.8,194.5c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C204.3,194.3,204.5,194.5,204.8,194.5z"
        />
        <path
            d="M199.7,198.1c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7C198.9,197.8,199.3,198.1,199.7,198.1z"
        />
        <path
            d="M194.4,201c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7C193.6,200.7,194,201,194.4,201z"/>
        <path
            d="M188.9,203.3c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C188.4,203.1,188.6,203.3,188.9,203.3z"
        />
        <path
            d="M183.2,205.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C182.7,205.2,182.9,205.5,183.2,205.5z"
        />
        <path
            d="M177.4,207.1c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C177,206.9,177.2,207.1,177.4,207.1z"/>
        <path
            d="M171.5,208.3c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C171.2,208.2,171.3,208.3,171.5,208.3z"
        />
        <path
            d="M165.5,209.4c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C165.1,209.2,165.3,209.4,165.5,209.4z"
        />
        <path
            d="M159.5,209.9c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C159.1,209.7,159.3,209.9,159.5,209.9z"
        />
        <path
            d="M153.4,210c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C153.1,209.8,153.2,210,153.4,210z"/>
        <path
            d="M147.4,209.8c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C146.9,209.5,147.1,209.8,147.4,209.8z"
        />
        <path
            d="M141.4,209.1c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C140.8,208.9,141.1,209.1,141.4,209.1z"
        />
        <path
            d="M135.4,207.9c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C135,207.7,135.2,207.9,135.4,207.9z"/>
        <path
            d="M129.6,206.6c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C128.8,206.3,129.2,206.6,129.6,206.6z"
        />
        <path d="M123.9,204.9c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1C122.9,204.4,123.3,204.9,123.9,204.9z"/>
        <path
            d="M118.3,202.4c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C117.5,202,117.8,202.4,118.3,202.4z"/>
        <path
            d="M112.9,199.6c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C112.1,199.3,112.4,199.6,112.9,199.6z"
        />
        <path
            d="M107.7,196.6c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9C106.8,196.3,107.2,196.6,107.7,196.6z"
        />
        <path
            d="M102.7,193.4c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C101.6,192.9,102.1,193.4,102.7,193.4z"
        />
        <path d="M98,189.1c0.3,0,0.6-0.3,0.6-0.6S98.3,188,98,188c-0.3,0-0.6,0.3-0.6,0.6S97.6,189.1,98,189.1z"/>
        <path
            d="M93.5,185c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C92.9,184.8,93.2,185,93.5,185z"/>
        <path
            d="M89.3,180.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C88.9,180.3,89.1,180.5,89.3,180.5z"/>
        <path
            d="M85.5,175.8c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C85,175.6,85.2,175.8,85.5,175.8z"/>
        <path
            d="M81.9,170.9c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C81.5,170.7,81.7,170.9,81.9,170.9z"/>
        <path
            d="M78.8,165.8c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C78.3,165.6,78.5,165.8,78.8,165.8z"/>
        <path
            d="M76,160.3c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.2-0.3,0.3C75.6,160.2,75.8,160.3,76,160.3z"/>
        <path
            d="M73.5,154.7c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C73.3,154.6,73.4,154.7,73.5,154.7z"/>
        <path d="M71.5,149c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3s-0.3,0.1-0.3,0.3C71.2,148.9,71.3,149,71.5,149z"/>
        <path d="M69.8,143.2c0.1,0,0.3-0.1,0.3-0.3s-0.1-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3S69.7,143.2,69.8,143.2z"/>
        <path
            d="M68.6,137.3c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C68.3,137.1,68.4,137.3,68.6,137.3z"/>
        <path
            d="M67.7,131.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C67.5,131.1,67.6,131.2,67.7,131.2z"/>
        <path
            d="M67.3,125.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C67.2,125,67.3,125.1,67.3,125.1z"/>
        <path
            d="M67.3,119.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C67.1,119,67.2,119.1,67.3,119.1z"/>
        <path
            d="M67.7,113.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C67.5,113,67.6,113.1,67.7,113.1z"/>
        <path d="M68.6,107C68.6,107,68.7,106.9,68.6,107c0.1-0.1,0-0.2,0-0.2C68.5,106.8,68.5,106.8,68.6,107
			C68.5,106.9,68.5,107,68.6,107z"/>
        <path
            d="M69.8,101.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C69.7,101,69.8,101.1,69.8,101.1z"/>
        <path d="M71.5,95.2C71.5,95.2,71.6,95.2,71.5,95.2c0.1-0.1,0-0.2,0-0.2C71.4,95,71.4,95.1,71.5,95.2
			C71.4,95.2,71.4,95.2,71.5,95.2z"/>
        <path d="M73.5,89.4C73.5,89.4,73.5,89.4,73.5,89.4C73.5,89.4,73.5,89.4,73.5,89.4C73.5,89.4,73.5,89.4,73.5,89.4
			C73.5,89.4,73.5,89.4,73.5,89.4z"/>
        <path
            d="M76,83.9C76,83.9,76,83.9,76,83.9C76,83.9,76,83.9,76,83.9C75.9,83.9,75.9,83.9,76,83.9C75.9,83.9,75.9,83.9,76,83.9z"/>
        <path
            d="M78.8,78.6C78.8,78.6,78.8,78.6,78.8,78.6c0.1-0.1,0-0.1,0-0.1S78.7,78.5,78.8,78.6C78.7,78.6,78.7,78.6,78.8,78.6z"/>
        <path d="M81.9,73.4C82,73.4,82,73.4,81.9,73.4c0.1-0.1,0-0.1,0-0.1C81.9,73.3,81.9,73.3,81.9,73.4C81.9,73.4,81.9,73.4,81.9,73.4z
			"/>
        <path d="M85.5,68.5C85.5,68.5,85.6,68.5,85.5,68.5c0.1-0.1,0-0.2,0-0.2C85.4,68.4,85.4,68.4,85.5,68.5
			C85.4,68.5,85.4,68.5,85.5,68.5z"/>
        <path d="M89.3,63.9C89.4,63.9,89.4,63.8,89.3,63.9c0.1-0.1,0-0.1,0-0.1C89.3,63.7,89.3,63.7,89.3,63.9
			C89.3,63.8,89.3,63.9,89.3,63.9z"/>
        <path
            d="M93.5,59.5C93.5,59.5,93.6,59.4,93.5,59.5c0.1-0.1,0-0.1,0-0.1S93.4,59.4,93.5,59.5C93.4,59.4,93.5,59.5,93.5,59.5z"/>
        <path d="M98,55.4C98,55.4,98,55.3,98,55.4c0.1-0.1,0-0.1,0-0.1S97.9,55.3,98,55.4C97.9,55.3,97.9,55.4,98,55.4z"/>
        <path d="M102.7,51.6C102.7,51.6,102.7,51.5,102.7,51.6C102.7,51.5,102.7,51.5,102.7,51.6C102.7,51.5,102.7,51.5,102.7,51.6
			C102.7,51.5,102.7,51.6,102.7,51.6z"/>
        <path d="M107.7,48.1C107.7,48.1,107.7,48.1,107.7,48.1C107.7,48.1,107.7,48.1,107.7,48.1C107.7,48.1,107.6,48.1,107.7,48.1
			C107.6,48.1,107.7,48.1,107.7,48.1z"/>
        <path d="M112.9,45C112.9,45,112.9,45,112.9,45C112.9,45,112.9,45,112.9,45C112.9,45,112.9,45,112.9,45C112.9,45,112.9,45,112.9,45
			z"/>
        <path d="M118.3,42.3C118.3,42.3,118.3,42.3,118.3,42.3C118.3,42.3,118.3,42.3,118.3,42.3C118.3,42.3,118.3,42.3,118.3,42.3
			C118.3,42.3,118.3,42.3,118.3,42.3z"/>
        <path d="M239.3,98C239.3,98,239.3,98,239.3,98C239.3,98,239.3,98,239.3,98C239.3,98,239.3,98,239.3,98C239.3,98,239.3,98,239.3,98
			z"/>
        <path d="M240.8,103.9C240.8,103.9,240.8,103.9,240.8,103.9C240.8,103.9,240.8,103.9,240.8,103.9
			C240.8,103.9,240.7,103.9,240.8,103.9C240.7,103.9,240.8,103.9,240.8,103.9z"/>
        <path d="M241.8,109.9C241.8,109.9,241.8,109.9,241.8,109.9C241.8,109.8,241.8,109.8,241.8,109.9
			C241.8,109.8,241.8,109.8,241.8,109.9C241.8,109.9,241.8,109.9,241.8,109.9z"/>
        <path d="M242.4,115.9C242.4,115.9,242.5,115.9,242.4,115.9C242.5,115.9,242.4,115.9,242.4,115.9
			C242.4,115.9,242.4,115.9,242.4,115.9C242.4,115.9,242.4,115.9,242.4,115.9z"/>
        <path d="M249.4,122C249.4,122,249.5,122,249.4,122c0.1-0.1,0-0.1,0-0.1C249.3,121.9,249.3,121.9,249.4,122
			C249.3,122,249.3,122,249.4,122z"/>
        <path d="M249.2,128C249.2,128,249.3,128,249.2,128c0.1-0.1,0-0.1,0-0.1C249.2,127.9,249.1,127.9,249.2,128
			C249.1,128,249.2,128,249.2,128z"/>
        <circle cx="248.6" cy="134" r="0.1"/>
        <path d="M247.6,140.1C247.7,140.1,247.7,140,247.6,140.1c0.1-0.1,0.1-0.2,0-0.2C247.6,139.9,247.6,139.9,247.6,140.1
			C247.6,140,247.6,140.1,247.6,140.1z"/>
        <path d="M246.3,146C246.3,146,246.4,145.9,246.3,146c0.1-0.1,0-0.2,0-0.2C246.2,145.8,246.2,145.8,246.3,146
			C246.2,145.9,246.2,146,246.3,146z"/>
        <path
            d="M244.6,151.9c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2s-0.2,0.1-0.2,0.2C244.4,151.8,244.5,151.9,244.6,151.9z"/>
        <path
            d="M242.5,157.6c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C242.2,157.5,242.3,157.6,242.5,157.6z"
        />
        <path
            d="M240,163C240.1,163,240.1,162.9,240,163c0.1-0.1,0-0.1,0-0.1C240,162.8,240,162.9,240,163C240,162.9,240,163,240,163z"/>
        <path d="M237.2,168.3C237.3,168.3,237.3,168.3,237.2,168.3c0.1-0.1,0-0.1,0-0.1C237.2,168.2,237.2,168.2,237.2,168.3
			C237.2,168.3,237.2,168.3,237.2,168.3z"/>
        <path
            d="M234.1,173.6c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1s-0.1,0.1-0.1,0.1C234,173.5,234,173.6,234.1,173.6z"/>
        <path d="M230.6,178.5C230.7,178.5,230.7,178.5,230.6,178.5c0.1-0.1,0.1-0.2,0-0.2C230.6,178.3,230.5,178.4,230.6,178.5
			C230.5,178.5,230.6,178.5,230.6,178.5z"/>
        <path
            d="M226.9,183.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C226.7,183.2,226.8,183.3,226.9,183.3z"
        />
        <path
            d="M222.8,187.9c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C222.5,187.8,222.6,187.9,222.8,187.9z"
        />
        <path
            d="M218.4,192.2c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C218,192.1,218.2,192.2,218.4,192.2z"/>
        <path
            d="M213.8,196c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C213.5,195.9,213.7,196,213.8,196z"/>
        <path
            d="M209,199.8c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C208.5,199.6,208.7,199.8,209,199.8z"/>
        <circle cx="203.9" cy="202.7" r="0.7"/>
        <path
            d="M198.6,206.5c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9C197.7,206.1,198.1,206.5,198.6,206.5z"
        />
        <path
            d="M193.2,208.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7C192.5,208.6,192.8,208.9,193.2,208.9z"
        />
        <path d="M187.6,211.5c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1s-1,0.4-1,1C186.6,211.1,187,211.5,187.6,211.5z"/>
        <path d="M181.8,213.4c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1C180.9,213,181.3,213.4,181.8,213.4z"/>
        <path
            d="M176,214.6c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6s-0.6,0.3-0.6,0.6C175.4,214.3,175.6,214.6,176,214.6z"/>
        <path
            d="M170,215.8c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C169.4,215.5,169.7,215.8,170,215.8z"/>
        <path
            d="M164,216.7c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7C163.3,216.3,163.6,216.7,164,216.7z"/>
        <path
            d="M158,216.8c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C157.5,216.6,157.7,216.8,158,216.8z"/>
        <path
            d="M151.9,217c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7C151.2,216.7,151.5,217,151.9,217z"/>
        <path
            d="M145.9,216.7c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C145.1,216.4,145.4,216.7,145.9,216.7z"
        />
        <path
            d="M139.9,216c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C139.1,215.6,139.4,216,139.9,216z"/>
        <path
            d="M133.9,214.8c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9C133.1,214.5,133.5,214.8,133.9,214.8z"
        />
        <path
            d="M128.1,213.6c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C127,213.1,127.5,213.6,128.1,213.6z"/>
        <path
            d="M122.3,211.8c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C121.1,211.3,121.6,211.8,122.3,211.8z"
        />
        <path
            d="M116.7,209.3c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C115.7,208.9,116.2,209.3,116.7,209.3z"
        />
        <path
            d="M111.3,207.2c0.9,0,1.5-0.7,1.5-1.5c0-0.9-0.7-1.5-1.5-1.5c-0.9,0-1.5,0.7-1.5,1.5C109.7,206.5,110.4,207.2,111.3,207.2z"
        />
        <path
            d="M106,204c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C104.7,203.4,105.3,204,106,204z"/>
        <path
            d="M100.9,200.8c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C99.5,200.2,100.2,200.8,100.9,200.8z"
        />
        <path
            d="M96.1,197c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2c-0.7,0-1.2,0.6-1.2,1.2C94.8,196.4,95.4,197,96.1,197z"/>
        <path
            d="M91.5,193.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C90.2,192.6,90.8,193.1,91.5,193.1z"/>
        <path
            d="M87.1,188.5c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9C86.3,188.1,86.7,188.5,87.1,188.5z"/>
        <path
            d="M83.1,183.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7C82.3,183.5,82.7,183.9,83.1,183.9z"/>
        <path
            d="M79.3,179.1c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7C78.6,178.8,78.9,179.1,79.3,179.1z"/>
        <path
            d="M75.8,174.1c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C75.2,173.8,75.5,174.1,75.8,174.1z"/>
        <path
            d="M72.7,168.8c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C72.2,168.5,72.4,168.8,72.7,168.8z"/>
        <path
            d="M69.9,163.5c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C69.3,163.2,69.6,163.5,69.9,163.5z"/>
        <path
            d="M67.4,157.9c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C66.9,157.7,67.1,157.9,67.4,157.9z"/>
        <path
            d="M65.3,152.3c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C64.8,152,65,152.3,65.3,152.3z"/>
        <path
            d="M63.6,146.3c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C63.2,146.1,63.4,146.3,63.6,146.3z"/>
        <circle cx="62.3" cy="140" r="0.4"/>
        <path
            d="M61.3,134.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C60.8,134.3,61,134.5,61.3,134.5z"/>
        <path
            d="M60.7,128.3c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C60.4,128.2,60.5,128.3,60.7,128.3z"/>
        <path
            d="M60.5,122.3c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.2-0.3,0.3C60.2,122.1,60.3,122.3,60.5,122.3z"/>
        <path
            d="M60.7,116.2c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C60.4,116,60.6,116.2,60.7,116.2z"/>
        <path d="M61.3,110.1c0.2,0,0.3-0.1,0.3-0.3s-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3S61.1,110.1,61.3,110.1z"/>
        <path
            d="M62.3,104.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C62,104,62.1,104.1,62.3,104.1z"/>
        <path
            d="M63.6,98.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1s-0.1,0.1-0.1,0.1C63.5,98.1,63.5,98.1,63.6,98.1z"/>
        <circle cx="65.3" cy="92.2" r="0.2"/>
        <path d="M67.4,86.6C67.5,86.6,67.5,86.5,67.4,86.6c0.1-0.1,0.1-0.2,0-0.2C67.4,86.4,67.3,86.4,67.4,86.6
			C67.3,86.5,67.4,86.6,67.4,86.6z"/>
        <path
            d="M69.9,81.1C70,81.1,70,81,70,81c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C69.7,81,69.8,81.1,69.9,81.1z"/>
        <path d="M72.7,75.7C72.7,75.7,72.8,75.6,72.7,75.7c0.1-0.1,0-0.1,0-0.1C72.6,75.5,72.6,75.6,72.7,75.7
			C72.6,75.6,72.6,75.7,72.7,75.7z"/>
        <path d="M75.8,70.6c0.1,0,0.1-0.1,0.1-0.1s-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1S75.7,70.6,75.8,70.6z"/>
        <path d="M79.3,65.5C79.3,65.5,79.4,65.5,79.3,65.5c0.1-0.1,0-0.2,0-0.2C79.2,65.4,79.2,65.4,79.3,65.5
			C79.2,65.5,79.2,65.5,79.3,65.5z"/>
        <path
            d="M83.1,60.8C83.1,60.8,83.1,60.8,83.1,60.8c0.1-0.1,0-0.1,0-0.1C83,60.7,83,60.7,83.1,60.8C83,60.8,83,60.8,83.1,60.8z"/>
        <path
            d="M87.1,56.3C87.2,56.3,87.2,56.3,87.1,56.3c0.1-0.1,0-0.1,0-0.1S87.1,56.2,87.1,56.3C87.1,56.3,87.1,56.3,87.1,56.3z"/>
        <path
            d="M91.5,52C91.5,52,91.5,52,91.5,52C91.5,52,91.5,52,91.5,52C91.5,52,91.5,52,91.5,52C91.5,52,91.5,52,91.5,52z"/>
        <path d="M247.6,103.9C247.7,103.9,247.7,103.9,247.6,103.9C247.7,103.9,247.7,103.9,247.6,103.9
			C247.6,103.9,247.6,103.9,247.6,103.9C247.6,103.9,247.6,103.9,247.6,103.9z"/>
        <path d="M248.6,109.9C248.6,109.9,248.6,109.9,248.6,109.9C248.6,109.8,248.6,109.8,248.6,109.9
			C248.6,109.8,248.6,109.8,248.6,109.9C248.6,109.9,248.6,109.9,248.6,109.9z"/>
        <path d="M249.2,115.9C249.2,115.9,249.2,115.9,249.2,115.9C249.2,115.9,249.2,115.9,249.2,115.9
			C249.2,115.9,249.2,115.9,249.2,115.9C249.2,115.9,249.2,115.9,249.2,115.9z"/>
        <path d="M256.1,122C256.2,122,256.2,122,256.1,122c0.1-0.1,0-0.1,0-0.1C256.1,121.9,256.1,121.9,256.1,122
			C256.1,122,256.1,122,256.1,122z"/>
        <path
            d="M255.9,128.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C255.7,128.1,255.8,128.2,255.9,128.2z"
        />
        <path
            d="M255.4,134.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C255.2,134.1,255.3,134.2,255.4,134.2z"
        />
        <path
            d="M254.5,140.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C254.3,140.1,254.4,140.2,254.5,140.2z"
        />
        <path d="M253.2,146.1c0.1,0,0.1-0.1,0.1-0.1s-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1S253.2,146.1,253.2,146.1z"/>
        <path
            d="M251.6,152.2c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C251.2,152,251.4,152.2,251.6,152.2z"/>
        <path
            d="M249.7,158.2c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7C248.9,157.9,249.3,158.2,249.7,158.2z"
        />
        <path
            d="M247.4,163.3c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3C247.1,163.2,247.2,163.3,247.4,163.3z"
        />
        <path
            d="M244.8,169.1c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C244.2,168.9,244.4,169.1,244.8,169.1z"
        />
        <path d="M241.8,174.3c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5S241.5,174.3,241.8,174.3z"/>
        <path
            d="M238.5,179.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C238.3,179,238.4,179.1,238.5,179.1z"/>
        <path
            d="M235,184.2c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C234.6,184,234.8,184.2,235,184.2z"/>
        <path
            d="M231.1,188.8c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.2-0.3,0.3C230.8,188.7,231,188.8,231.1,188.8z"/>
        <path
            d="M227,193.3c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.2-0.3,0.3C226.7,193.1,226.8,193.3,227,193.3z"/>
        <path
            d="M222.7,197.7c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C222.1,197.4,222.3,197.7,222.7,197.7z"
        />
        <path
            d="M218,201.7c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C217.4,201.4,217.7,201.7,218,201.7z"/>
        <path
            d="M213.2,205.5c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9C212.3,205.1,212.7,205.5,213.2,205.5z"
        />
        <path
            d="M208.1,208.8c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9C207.3,208.5,207.7,208.8,208.1,208.8z"
        />
        <path
            d="M202.9,212.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C201.6,211.7,202.2,212.3,202.9,212.3z"
        />
        <circle cx="197.5" cy="213.7" r="1.1"/>
        <path d="M191.9,217.1c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1C191,216.6,191.4,217.1,191.9,217.1z"/>
        <path d="M186.2,219.1c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1C185.3,218.7,185.7,219.1,186.2,219.1z"/>
        <path d="M180.4,220.8c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1C179.4,220.4,179.9,220.8,180.4,220.8z"/>
        <path
            d="M174.5,222c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C173.7,221.6,174.1,222,174.5,222z"/>
        <path
            d="M168.5,222.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7C167.8,222.6,168.1,222.9,168.5,222.9z"
        />
        <path
            d="M162.5,223.6c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C161.7,223.3,162.1,223.6,162.5,223.6z"
        />
        <path
            d="M156.5,224c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9C155.6,223.6,156,224,156.5,224z"/>
        <path
            d="M150.4,224.1c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C149.3,223.6,149.8,224.1,150.4,224.1z"
        />
        <path
            d="M144.4,223.7c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1C143.2,223.2,143.8,223.7,144.4,223.7z"/>
        <path
            d="M138.4,223c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C137.1,222.4,137.7,223,138.4,223z"/>
        <path
            d="M132.4,222c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C131.1,221.3,131.7,222,132.4,222z"/>
        <path
            d="M126.6,220.4c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C125.2,219.8,125.8,220.4,126.6,220.4z"
        />
        <path
            d="M120.8,218.7c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C119.3,218,120,218.7,120.8,218.7z"/>
        <path
            d="M115.2,216.5c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5C113.7,215.8,114.4,216.5,115.2,216.5z"/>
        <path
            d="M109.7,214.2c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C108,213.4,108.7,214.2,109.7,214.2z"/>
        <path
            d="M104.4,211.1c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C102.8,210.4,103.5,211.1,104.4,211.1z"
        />
        <path
            d="M99.2,208c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.7,1.7C97.6,207.3,98.3,208,99.2,208z"/>
        <circle cx="94.3" cy="202.9" r="1.5"/>
        <path
            d="M89.5,200.9c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8C87.7,200.1,88.5,200.9,89.5,200.9z"/>
        <path
            d="M85,196.7c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.7,1.7C83.4,196,84.1,196.7,85,196.7z"/>
        <path
            d="M80.8,192.4c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.7,1.7C79.1,191.7,79.9,192.4,80.8,192.4z"/>
        <path
            d="M76.8,187.4c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2C75.6,186.8,76.2,187.4,76.8,187.4z"/>
        <path d="M73.1,182.4c0.5,0,1-0.4,1-1s-0.4-1-1-1c-0.5,0-1,0.4-1,1S72.6,182.4,73.1,182.4z"/>
        <circle cx="69.7" cy="176.4" r="1"/>
        <path d="M66.6,172.2c0.6,0,1-0.5,1-1s-0.5-1-1-1c-0.6,0-1,0.5-1,1S66,172.2,66.6,172.2z"/>
        <path
            d="M63.8,166.7c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9C62.9,166.3,63.3,166.7,63.8,166.7z"/>
        <path d="M61.3,161c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7C60.6,160.7,60.9,161,61.3,161z"/>
        <path
            d="M59.2,155.2c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C58.6,155,58.9,155.2,59.2,155.2z"/>
        <path
            d="M57.4,149.6c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7C56.7,149.2,57,149.6,57.4,149.6z"/>
        <path
            d="M56,143.4c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C55.5,143.2,55.7,143.4,56,143.4z"/>
        <path d="M54.9,137.5c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5S54.7,137.5,54.9,137.5z"/>
        <path
            d="M54.2,131.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C53.7,131.3,53.9,131.5,54.2,131.5z"/>
        <path
            d="M53.8,125.4c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5C53.4,125.2,53.6,125.4,53.8,125.4z"/>
        <path
            d="M53.8,119.3c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C53.4,119.1,53.6,119.3,53.8,119.3z"/>
        <path
            d="M54.2,113.2c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.2-0.3,0.3C53.9,113,54,113.2,54.2,113.2z"/>
        <path
            d="M54.9,107.2c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C54.6,107,54.7,107.2,54.9,107.2z"/>
        <path
            d="M56,101.2c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.2-0.3,0.3C55.7,101.1,55.8,101.2,56,101.2z"/>
        <path
            d="M57.4,95.4c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.2-0.3,0.3C57.1,95.2,57.2,95.4,57.4,95.4z"/>
        <path d="M59.2,89.5c0.1,0,0.2-0.1,0.2-0.2S59.3,89,59.2,89c-0.1,0-0.2,0.1-0.2,0.2S59.1,89.5,59.2,89.5z"/>
        <path d="M61.3,83.8c0.1,0,0.3-0.1,0.3-0.3s-0.1-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3S61.2,83.8,61.3,83.8z"/>
        <path
            d="M63.8,78.4c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C63.4,78.3,63.6,78.4,63.8,78.4z"/>
        <path
            d="M66.6,72.9c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C66.4,72.8,66.5,72.9,66.6,72.9z"/>
        <path
            d="M69.7,67.7c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C69.5,67.6,69.6,67.7,69.7,67.7z"/>
        <path d="M73.1,62.5C73.1,62.5,73.2,62.5,73.1,62.5c0.1-0.1,0-0.1,0-0.1C73.1,62.4,73,62.4,73.1,62.5C73,62.5,73.1,62.5,73.1,62.5z
			"/>
        <path d="M76.8,57.8C76.9,57.8,76.9,57.7,76.8,57.8c0.1-0.1,0-0.2,0-0.2C76.8,57.6,76.7,57.6,76.8,57.8
			C76.7,57.7,76.8,57.8,76.8,57.8z"/>
        <path
            d="M262.2,134.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C262.1,134.1,262.1,134.1,262.2,134.1z"
        />
        <path
            d="M261.3,140.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2s-0.2,0.1-0.2,0.2C261.1,140.1,261.2,140.2,261.3,140.2z"/>
        <path
            d="M260.2,146.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C260,146,260.1,146.1,260.2,146.1z"/>
        <path
            d="M258.7,152.1c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3C258.4,152,258.5,152.1,258.7,152.1z"/>
        <path
            d="M256.8,158.1c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C256.3,157.9,256.5,158.1,256.8,158.1z"
        />
        <path
            d="M254.7,164c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C253.9,163.7,254.2,164,254.7,164z"/>
        <path d="M252.2,169.6c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9S251.7,169.6,252.2,169.6z"/>
        <path
            d="M249.4,175.4c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2c-0.7,0-1.2,0.6-1.2,1.2C248.2,174.8,248.7,175.4,249.4,175.4z"
        />
        <path
            d="M246.3,179.9c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C245.8,179.6,246,179.9,246.3,179.9z"/>
        <path d="M243,185c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6s-0.6,0.3-0.6,0.6C242.4,184.7,242.6,185,243,185z"/>
        <path
            d="M239.3,189.6c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C239,189.4,239.1,189.6,239.3,189.6z"/>
        <path
            d="M235.4,194.4c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C234.8,194.2,235.1,194.4,235.4,194.4z"
        />
        <circle cx="231.3" cy="198.2" r="0.7"/>
        <path
            d="M226.9,203c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C226.3,202.7,226.6,203,226.9,203z"/>
        <path
            d="M222.2,207.4c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C221.1,206.9,221.6,207.4,222.2,207.4z"
        />
        <path
            d="M217.4,211.1c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C216.3,210.6,216.8,211.1,217.4,211.1z"
        />
        <path
            d="M212.4,214.4c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1C211.2,213.9,211.7,214.4,212.4,214.4z"/>
        <path
            d="M207.2,217.7c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C205.9,217.1,206.5,217.7,207.2,217.7z"
        />
        <path
            d="M201.8,220.7c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6s-1.6,0.7-1.6,1.6C200.2,220,200.9,220.7,201.8,220.7z"/>
        <path
            d="M196.3,222.9c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C195,222.4,195.5,222.9,196.3,222.9z"/>
        <path
            d="M190.6,225c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2C189.4,224.4,189.9,225,190.6,225z"/>
        <path
            d="M184.8,226.9c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C183.6,226.3,184.1,226.9,184.8,226.9z"
        />
        <path
            d="M179,228.5c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C177.6,227.9,178.2,228.5,179,228.5z"/>
        <path
            d="M173,229.6c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C171.7,229,172.3,229.6,173,229.6z"/>
        <circle cx="167" cy="229.2" r="1.3"/>
        <path
            d="M161,231.2c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5C159.5,230.5,160.2,231.2,161,231.2z"/>
        <path
            d="M155,231.3c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5C153.5,230.7,154.1,231.3,155,231.3z"/>
        <path
            d="M148.9,231.3c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C147.3,230.6,148,231.3,148.9,231.3z"/>
        <path
            d="M142.9,230.7c0.9,0,1.5-0.7,1.5-1.5c0-0.9-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5C141.3,230,142,230.7,142.9,230.7z"/>
        <path
            d="M136.9,230.1c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8C135.1,229.3,135.9,230.1,136.9,230.1z"/>
        <path
            d="M130.9,228.8c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C129.3,228,130,228.8,130.9,228.8z"/>
        <path
            d="M125.1,227.3c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7s-1.7,0.8-1.7,1.7C123.4,226.6,124.2,227.3,125.1,227.3z"/>
        <path
            d="M119.3,225.4c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C117.7,224.7,118.4,225.4,119.3,225.4z"
        />
        <path
            d="M113.7,223.3c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C112,222.5,112.8,223.3,113.7,223.3z"/>
        <path
            d="M108.1,220.9c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C106.4,220.1,107.2,220.9,108.1,220.9z"/>
        <path
            d="M102.8,218.1c1,0,1.7-0.8,1.7-1.8c0-1-0.8-1.8-1.7-1.8c-1,0-1.8,0.8-1.8,1.8C101,217.3,101.8,218.1,102.8,218.1z"/>
        <path d="M97.5,215.2c1,0,1.9-0.8,1.9-1.9s-0.8-1.9-1.9-1.9c-1,0-1.9,0.8-1.9,1.9S96.5,215.2,97.5,215.2z"/>
        <path
            d="M92.5,211.6c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.7,1.7C90.8,210.9,91.6,211.6,92.5,211.6z"/>
        <path
            d="M87.7,208.1c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C85.9,207.3,86.7,208.1,87.7,208.1z"/>
        <circle cx="83" cy="202.4" r="1.9"/>
        <path
            d="M78.7,200.2c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C76.7,199.3,77.6,200.2,78.7,200.2z"/>
        <path
            d="M74.5,195.7c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9c-1,0-1.9,0.8-1.9,1.9C72.6,194.9,73.5,195.7,74.5,195.7z"/>
        <path
            d="M70.6,190.8c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C69,190.1,69.7,190.8,70.6,190.8z"/>
        <path
            d="M66.9,186c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C65.4,185.3,66.1,186,66.9,186z"/>
        <path
            d="M63.6,180.9c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C62,180.2,62.7,180.9,63.6,180.9z"/>
        <path
            d="M60.5,175.3c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C59.4,174.8,59.9,175.3,60.5,175.3z"/>
        <path d="M57.7,170c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2C56.5,169.4,57,170,57.7,170z"/>
        <path
            d="M55.3,164.5c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2c-0.7,0-1.2,0.6-1.2,1.2C54,163.9,54.6,164.5,55.3,164.5z"/>
        <path d="M53.1,158.6c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1C52.1,158.1,52.6,158.6,53.1,158.6z"/>
        <path
            d="M51.3,152.9c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C50.2,152.4,50.7,152.9,51.3,152.9z"/>
        <path
            d="M49.7,146.7c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C49,146.4,49.3,146.7,49.7,146.7z"/>
        <path
            d="M48.6,140.7c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7C47.8,140.4,48.2,140.7,48.6,140.7z"/>
        <path
            d="M47.7,134.8c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7C47,134.4,47.3,134.8,47.7,134.8z"/>
        <path d="M47.2,128.7c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7S46.8,128.7,47.2,128.7z"/>
        <path
            d="M47,122.7c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7C46.3,122.3,46.6,122.7,47,122.7z"/>
        <path
            d="M47.2,116.4c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C46.7,116.2,46.9,116.4,47.2,116.4z"/>
        <path d="M47.7,110.4c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6S47.4,110.4,47.7,110.4z"/>
        <path
            d="M48.6,104.4c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C48,104.2,48.3,104.4,48.6,104.4z"/>
        <circle cx="49.7" cy="97.9" r="0.4"/>
        <path d="M51.3,92.5c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5S51,92.5,51.3,92.5z"/>
        <path
            d="M53.1,86.6c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C52.8,86.4,52.9,86.6,53.1,86.6z"/>
        <path
            d="M55.3,80.9c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C55,80.8,55.1,80.9,55.3,80.9z"/>
        <path
            d="M57.7,75.4c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C57.4,75.3,57.6,75.4,57.7,75.4z"/>
        <path
            d="M60.5,70c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C60.2,69.9,60.3,70,60.5,70z"/>
        <path d="M63.6,64.6C63.6,64.6,63.7,64.6,63.6,64.6c0.1-0.1,0-0.2,0-0.2C63.5,64.4,63.5,64.5,63.6,64.6
			C63.5,64.6,63.5,64.6,63.6,64.6z"/>
        <path d="M66.9,59.5C67,59.5,67,59.5,66.9,59.5C67,59.5,67,59.5,66.9,59.5C66.9,59.5,66.9,59.5,66.9,59.5
			C66.9,59.5,66.9,59.5,66.9,59.5z"/>
        <path
            d="M267.1,146.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C266.8,146.1,266.9,146.2,267.1,146.2z"
        />
        <path
            d="M265.6,152.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C265.4,152,265.5,152.1,265.6,152.1z"/>
        <path
            d="M263.9,157.8c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C263.8,157.7,263.8,157.8,263.9,157.8z"
        />
        <path
            d="M261.9,163.5c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C261.7,163.4,261.8,163.5,261.9,163.5z"
        />
        <path
            d="M259.5,169.7c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C258.8,169.4,259.1,169.7,259.5,169.7z"
        />
        <path
            d="M256.9,175.6c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C255.7,175.1,256.2,175.6,256.9,175.6z"
        />
        <path d="M254,180.7c0.6,0,1-0.5,1-1c0-0.6-0.5-1-1-1c-0.6,0-1,0.5-1,1C253,180.3,253.4,180.7,254,180.7z"/>
        <path d="M250.8,185.9c0.6,0,1-0.5,1-1c0-0.6-0.5-1-1-1c-0.6,0-1,0.5-1,1C249.8,185.4,250.2,185.9,250.8,185.9z"/>
        <path
            d="M247.4,190.4c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C246.8,190.1,247,190.4,247.4,190.4z"/>
        <path
            d="M243.7,195.5c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9C242.8,195.1,243.2,195.5,243.7,195.5z"
        />
        <path
            d="M239.7,200.1c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9C238.8,199.7,239.2,200.1,239.7,200.1z"
        />
        <path d="M235.5,204.4c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9S235,204.4,235.5,204.4z"/>
        <path
            d="M231.1,208.8c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C230,208.3,230.5,208.8,231.1,208.8z"/>
        <path
            d="M226.4,213c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5C225,212.4,225.6,213,226.4,213z"/>
        <path
            d="M221.6,216.7c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5C220.2,216,220.8,216.7,221.6,216.7z"/>
        <path
            d="M216.6,220.3c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C215,219.5,215.7,220.3,216.6,220.3z"/>
        <path
            d="M211.4,223.4c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7s-1.7,0.7-1.7,1.7C209.8,222.6,210.5,223.4,211.4,223.4z"/>
        <path
            d="M206.1,226.3c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C204.3,225.5,205.1,226.3,206.1,226.3z"/>
        <path
            d="M200.6,228.9c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C198.8,228.1,199.6,228.9,200.6,228.9z"/>
        <path
            d="M195,231.2c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C193.2,230.4,194,231.2,195,231.2z"/>
        <path d="M189.2,233.4c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C187.2,232.5,188.1,233.4,189.2,233.4z"/>
        <path
            d="M183.4,234.9c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C181.5,234.1,182.4,234.9,183.4,234.9z"
        />
        <path d="M177.5,236.1c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8S176.5,236.1,177.5,236.1z"/>
        <path
            d="M171.5,237c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C170,236.3,170.7,237,171.5,237z"/>
        <path
            d="M165.5,237.9c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C163.8,237.1,164.6,237.9,165.5,237.9z"/>
        <path
            d="M159.5,238.3c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C157.7,237.5,158.5,238.3,159.5,238.3z"/>
        <path
            d="M153.4,238.4c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C151.6,237.6,152.4,238.4,153.4,238.4z"/>
        <path d="M147.4,238.3c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C145.4,237.4,146.3,238.3,147.4,238.3z"/>
        <path d="M141.4,237.5c0.9,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7S140.4,237.5,141.4,237.5z"/>
        <path
            d="M135.4,236.6c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7C133.7,235.8,134.4,236.6,135.4,236.6z"
        />
        <path
            d="M129.4,235.6c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9s-1.9,0.8-1.9,1.9C127.6,234.7,128.4,235.6,129.4,235.6z"/>
        <path
            d="M123.6,234.3c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c-1.2,0-2.1,0.9-2.1,2.1C121.5,233.4,122.4,234.3,123.6,234.3z"
        />
        <path d="M117.8,232.4c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C115.9,231.5,116.7,232.4,117.8,232.4z"/>
        <path
            d="M112.1,230.2c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C110.2,229.3,111.1,230.2,112.1,230.2z"
        />
        <path
            d="M106.6,227.8c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C104.7,226.9,105.5,227.8,106.6,227.8z"
        />
        <path
            d="M101.2,225c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9c-1,0-1.9,0.8-1.9,1.9C99.3,224.2,100.1,225,101.2,225z"/>
        <path
            d="M95.9,222.4c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C93.7,221.4,94.7,222.4,95.9,222.4z"/>
        <path d="M90.8,219c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C88.8,218.1,89.7,219,90.8,219z"/>
        <path d="M85.9,215.5c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C83.8,214.5,84.7,215.5,85.9,215.5z"/>
        <path
            d="M81.1,211.5c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8C79.3,210.7,80.1,211.5,81.1,211.5z"/>
        <path
            d="M76.6,207.6c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9s-1.9,0.9-1.9,1.9C74.7,206.7,75.5,207.6,76.6,207.6z"/>
        <path
            d="M72.3,203.3c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C70.4,202.5,71.2,203.3,72.3,203.3z"/>
        <path
            d="M68.2,198.6c1,0,1.7-0.8,1.7-1.7c0-1-0.8-1.7-1.7-1.7s-1.7,0.8-1.7,1.7C66.5,197.9,67.3,198.6,68.2,198.6z"/>
        <path d="M64.4,194.1c1.1,0,1.9-0.9,1.9-1.9s-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9S63.3,194.1,64.4,194.1z"/>
        <path
            d="M60.8,189c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.7,1.7C59.1,188.3,59.9,189,60.8,189z"/>
        <path
            d="M57.5,183.8c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5C56,183.1,56.7,183.8,57.5,183.8z"/>
        <path
            d="M54.4,178.5c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5C53,177.9,53.6,178.5,54.4,178.5z"/>
        <path d="M51.7,172.7c0.6,0,1-0.5,1-1s-0.5-1-1-1c-0.6,0-1,0.5-1,1S51.1,172.7,51.7,172.7z"/>
        <path
            d="M49.2,167.3c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C48,166.8,48.6,167.3,49.2,167.3z"/>
        <path
            d="M47,161.4c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9C46.1,161,46.5,161.4,47,161.4z"/>
        <path
            d="M45.1,155.5c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7C44.4,155.1,44.7,155.5,45.1,155.5z"/>
        <path
            d="M43.5,149.5c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C43,149.2,43.2,149.5,43.5,149.5z"/>
        <path
            d="M42.2,143.6c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C41.7,143.3,41.9,143.6,42.2,143.6z"/>
        <path
            d="M41.3,137.8c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C40.5,137.5,40.9,137.8,41.3,137.8z"/>
        <path
            d="M40.7,131.6c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C40,131.3,40.3,131.6,40.7,131.6z"/>
        <path
            d="M40.3,125.5c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C39.8,125.3,40,125.5,40.3,125.5z"/>
        <path
            d="M40.3,119.5c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C39.8,119.2,40,119.5,40.3,119.5z"/>
        <path d="M40.7,113.4c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5S40.4,113.4,40.7,113.4z"/>
        <circle cx="41.3" cy="106.8" r="0.7"/>
        <path
            d="M42.2,101.4c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C41.7,101.1,42,101.4,42.2,101.4z"/>
        <path
            d="M43.5,95.3c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.2-0.3,0.3C43.2,95.1,43.3,95.3,43.5,95.3z"/>
        <path
            d="M45.1,89.4c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.2-0.3,0.3C44.8,89.3,44.9,89.4,45.1,89.4z"/>
        <path
            d="M47,83.7c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.2-0.3,0.3C46.6,83.5,46.8,83.7,47,83.7z"/>
        <path
            d="M49.2,77.9c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C49,77.8,49.1,77.9,49.2,77.9z"/>
        <path
            d="M266.8,169.2c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C266.7,169.2,266.8,169.2,266.8,169.2z"
        />
        <path d="M264.3,174.8c0.1,0,0.2-0.1,0.2-0.2s-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2S264.3,174.8,264.3,174.8z"/>
        <path d="M261.6,180.1C261.6,180.1,261.7,180,261.6,180.1c0.1-0.1,0-0.2,0-0.2C261.5,179.9,261.5,179.9,261.6,180.1
			C261.5,180,261.5,180.1,261.6,180.1z"/>
        <path
            d="M258.6,185.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7C257.9,185.6,258.2,185.9,258.6,185.9z"/>
        <path
            d="M255.3,190.6c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3C255,190.5,255.1,190.6,255.3,190.6z"/>
        <path
            d="M251.7,196.4c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2c-0.6,0-1.2,0.5-1.2,1.2C250.6,195.9,251.1,196.4,251.7,196.4z"
        />
        <path
            d="M248,200.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9C247,200.5,247.4,200.9,248,200.9z"/>
        <path
            d="M244,205.7c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2c-0.7,0-1.2,0.6-1.2,1.2C242.7,205.2,243.3,205.7,244,205.7z"/>
        <path
            d="M239.7,210.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C238.5,209.5,239,210.1,239.7,210.1z"/>
        <path
            d="M235.3,214.4c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5C233.8,213.8,234.5,214.4,235.3,214.4z"
        />
        <path
            d="M230.7,218.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C229.4,217.5,230,218.1,230.7,218.1z"/>
        <path
            d="M225.8,222.3c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C224,221.5,224.8,222.3,225.8,222.3z"/>
        <path
            d="M220.8,225.5c0.9,0,1.5-0.7,1.5-1.5c0-0.9-0.7-1.5-1.5-1.5c-0.9,0-1.5,0.7-1.5,1.5C219.3,224.8,220,225.5,220.8,225.5z"/>
        <path d="M215.7,228.9c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8S214.7,228.9,215.7,228.9z"/>
        <path d="M210.3,231.7c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8S209.4,231.7,210.3,231.7z"/>
        <path d="M204.9,234.4c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8S203.9,234.4,204.9,234.4z"/>
        <path
            d="M199.3,236.9c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9s-1.9,0.9-1.9,1.9C197.4,236,198.2,236.9,199.3,236.9z"/>
        <path d="M193.6,239c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C191.6,238.1,192.5,239,193.6,239z"/>
        <path
            d="M187.8,240.9c1.2,0,2.1-1,2.1-2.1c0-1.2-1-2.1-2.1-2.1c-1.2,0-2.1,1-2.1,2.1C185.7,240,186.7,240.9,187.8,240.9z"/>
        <path
            d="M182,242.4c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C179.8,241.5,180.8,242.4,182,242.4z"/>
        <path d="M176,243.4c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C174.1,242.6,175,243.4,176,243.4z"/>
        <path d="M170.1,244.4c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C168.1,243.5,169,244.4,170.1,244.4z"/>
        <path d="M164,245c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C162,244.1,162.9,245,164,245z"/>
        <path
            d="M158,245.2c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C156.1,244.4,156.9,245.2,158,245.2z"/>
        <path
            d="M151.9,245.4c1.2,0,2.1-1,2.1-2.1c0-1.2-1-2.1-2.1-2.1c-1.2,0-2.1,1-2.1,2.1C149.8,244.5,150.8,245.4,151.9,245.4z"/>
        <path d="M145.9,245c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C143.9,244.1,144.8,245,145.9,245z"/>
        <path d="M139.9,244.4c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C137.9,243.5,138.8,244.4,139.9,244.4z"/>
        <path
            d="M133.9,243.4c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C131.9,242.6,132.8,243.4,133.9,243.4z"
        />
        <path
            d="M127.9,242.4c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c-1.2,0-2.1,0.9-2.1,2.1C125.9,241.4,126.8,242.4,127.9,242.4z"
        />
        <path
            d="M122.1,240.9c1.1,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1C120,239.9,120.9,240.9,122.1,240.9z"/>
        <path
            d="M116.3,239.1c1.2,0,2.1-1,2.1-2.1c0-1.2-1-2.1-2.1-2.1c-1.2,0-2.1,1-2.1,2.1C114.2,238.2,115.1,239.1,116.3,239.1z"/>
        <path
            d="M110.6,237.1c1.2,0,2.1-1,2.1-2.1c0-1.2-1-2.1-2.1-2.1c-1.2,0-2.1,1-2.1,2.1C108.5,236.1,109.4,237.1,110.6,237.1z"/>
        <path d="M105,234.8c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2S103.8,234.8,105,234.8z"/>
        <path
            d="M99.6,232c1.1,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1C97.5,231.1,98.4,232,99.6,232z"/>
        <path
            d="M94.3,229.2c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C92.1,228.3,93.1,229.2,94.3,229.2z"/>
        <path
            d="M89.1,226.1c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C86.9,225.1,87.9,226.1,89.1,226.1z"/>
        <path
            d="M84.1,222.6c1.1,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1C82,221.6,82.9,222.6,84.1,222.6z"/>
        <path d="M79.3,218.8c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C77.3,218,78.2,218.8,79.3,218.8z"/>
        <path d="M74.6,215c1.1,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1s-2.1,0.9-2.1,2.1C72.6,214.1,73.5,215,74.6,215z"/>
        <path
            d="M70.2,210.7c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C68.3,209.9,69.1,210.7,70.2,210.7z"/>
        <path
            d="M66,206.4c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C64.1,205.6,64.9,206.4,66,206.4z"/>
        <path d="M62,202c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C59.9,201.1,60.8,202,62,202z"/>
        <path
            d="M58.2,197.3c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c-1.2,0-2.1,0.9-2.1,2.1C56.1,196.4,57,197.3,58.2,197.3z"/>
        <path
            d="M54.6,192.4c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c-1.2,0-2.1,0.9-2.1,2.1C52.6,191.5,53.5,192.4,54.6,192.4z"/>
        <path d="M51.4,187c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8C49.6,186.2,50.4,187,51.4,187z"/>
        <path
            d="M48.3,181.7c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7C46.7,180.9,47.4,181.7,48.3,181.7z"/>
        <path d="M45.6,175.6c0.6,0,1-0.5,1-1c0-0.6-0.5-1-1-1c-0.6,0-1,0.5-1,1C44.5,175.2,45,175.6,45.6,175.6z"/>
        <path
            d="M43.1,170c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9C42.2,169.6,42.6,170,43.1,170z"/>
        <circle cx="40.9" cy="163.5" r="0.9"/>
        <path
            d="M38.9,158.6c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9C38.1,158.2,38.5,158.6,38.9,158.6z"/>
        <path
            d="M37.3,152.7c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C36.5,152.3,36.9,152.7,37.3,152.7z"/>
        <path
            d="M36,146.7c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7C35.3,146.4,35.6,146.7,36,146.7z"/>
        <circle cx="34.9" cy="140" r="0.5"/>
        <path
            d="M34.2,134.6c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C33.6,134.3,33.8,134.6,34.2,134.6z"/>
        <circle cx="33.7" cy="128" r="0.5"/>
        <path
            d="M33.6,122.4c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5C33.1,122.2,33.3,122.4,33.6,122.4z"/>
        <path
            d="M33.7,116.3c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C33.2,116.1,33.4,116.3,33.7,116.3z"/>
        <path
            d="M34.2,110.3c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C33.7,110.1,33.9,110.3,34.2,110.3z"/>
        <circle cx="34.9" cy="103.8" r="0.4"/>
        <path
            d="M36,98.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C35.8,98,35.8,98.1,36,98.1z"/>
        <path d="M38.9,86.2C39,86.2,39,86.2,38.9,86.2C39,86.1,39,86.1,38.9,86.2C38.9,86.1,38.9,86.1,38.9,86.2
			C38.9,86.2,38.9,86.2,38.9,86.2z"/>
        <path d="M266.2,185.6C266.2,185.6,266.2,185.6,266.2,185.6C266.2,185.6,266.2,185.5,266.2,185.6
			C266.2,185.5,266.2,185.6,266.2,185.6C266.2,185.6,266.2,185.6,266.2,185.6z"/>
        <path
            d="M259.7,196c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3s-0.3,0.1-0.3,0.3C259.4,195.9,259.5,196,259.7,196z"/>
        <path
            d="M256.1,200.8c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C255.9,200.7,256,200.8,256.1,200.8z"/>
        <circle cx="252.3" cy="205.3" r="0.4"/>
        <path
            d="M248.2,210.2c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C247.8,210.1,248,210.2,248.2,210.2z"/>
        <path
            d="M244,214.9c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C243.1,214.6,243.5,214.9,244,214.9z"/>
        <path
            d="M239.5,219.3c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C238.4,218.8,238.9,219.3,239.5,219.3z"
        />
        <path
            d="M234.9,223.2c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C233.8,222.7,234.3,223.2,234.9,223.2z"
        />
        <path
            d="M230,227.3c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5C228.5,226.6,229.2,227.3,230,227.3z"/>
        <path d="M225,230.8c0.9,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5c-0.9,0-1.5,0.7-1.5,1.5S224.2,230.8,225,230.8z"/>
        <path
            d="M219.9,233.8c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C218.5,233.2,219.1,233.8,219.9,233.8z"
        />
        <path
            d="M214.6,236.8c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4s-1.4,0.6-1.4,1.4C213.2,236.1,213.8,236.8,214.6,236.8z"/>
        <path d="M209.2,239.6c0.9,0,1.6-0.7,1.6-1.6s-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6S208.3,239.6,209.2,239.6z"/>
        <path
            d="M203.7,242c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C202.1,241.3,202.8,242,203.7,242z"/>
        <circle cx="198" cy="242.6" r="1.9"/>
        <path
            d="M192.2,246.4c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9c-1,0-1.9,0.8-1.9,1.9C190.4,245.6,191.2,246.4,192.2,246.4z"/>
        <path
            d="M186.4,248.1c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C184.5,247.2,185.4,248.1,186.4,248.1z"
        />
        <path
            d="M180.5,249.4c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C178.6,248.6,179.5,249.4,180.5,249.4z"
        />
        <path d="M174.6,250.5c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C172.6,249.7,173.5,250.5,174.6,250.5z"/>
        <path
            d="M168.6,251.4c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c-1.2,0-2.1,0.9-2.1,2.1C166.5,250.5,167.4,251.4,168.6,251.4z"
        />
        <path
            d="M162.5,252.2c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C160.2,251.1,161.2,252.2,162.5,252.2z"/>
        <path
            d="M156.5,252.4c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C154.2,251.3,155.2,252.4,156.5,252.4z"/>
        <path
            d="M150.4,252.2c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C148.3,251.2,149.2,252.2,150.4,252.2z"/>
        <circle cx="144.4" cy="249.6" r="1.6"/>
        <path
            d="M138.4,250.8c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C136.5,250,137.3,250.8,138.4,250.8z"/>
        <path
            d="M132.4,250.3c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C130.1,249.3,131.1,250.3,132.4,250.3z"/>
        <path
            d="M126.4,249.1c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C124.2,248.1,125.2,249.1,126.4,249.1z"/>
        <path
            d="M120.6,247.7c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C118.2,246.7,119.3,247.7,120.6,247.7z"/>
        <path
            d="M114.8,245.7c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c-1.2,0-2.1,0.9-2.1,2.1C112.7,244.8,113.6,245.7,114.8,245.7z"
        />
        <path
            d="M109.1,243.8c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C106.9,242.8,107.9,243.8,109.1,243.8z"/>
        <path
            d="M103.5,241.5c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C101.3,240.5,102.3,241.5,103.5,241.5z"/>
        <path d="M98,238.9c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2s-2.2,1-2.2,2.2C95.8,237.9,96.8,238.9,98,238.9z"/>
        <path
            d="M92.6,236.1c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C90.5,235.1,91.4,236.1,92.6,236.1z"/>
        <path
            d="M87.4,232.9c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c-1.2,0-2.1,0.9-2.1,2.1C85.3,232,86.3,232.9,87.4,232.9z"/>
        <path
            d="M82.4,229.7c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C80.2,228.7,81.2,229.7,82.4,229.7z"/>
        <path
            d="M77.4,226.1c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C75.3,225.2,76.3,226.1,77.4,226.1z"/>
        <path
            d="M72.7,222.3c1.2,0,2.1-1,2.1-2.1c0-1.2-1-2.1-2.1-2.1c-1.2,0-2.1,1-2.1,2.1C70.6,221.4,71.5,222.3,72.7,222.3z"/>
        <path
            d="M68.2,218.3c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c-1.2,0-2.1,0.9-2.1,2.1C66.1,217.4,67,218.3,68.2,218.3z"/>
        <circle cx="63.8" cy="212" r="2.2"/>
        <circle cx="59.7" cy="207.6" r="2.1"/>
        <circle cx="55.7" cy="203" r="2.2"/>
        <path
            d="M52,200.5c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C49.8,199.5,50.8,200.5,52,200.5z"/>
        <path
            d="M48.5,195.4c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c-1.2,0-2.1,0.9-2.1,2.1C46.4,194.4,47.4,195.4,48.5,195.4z"/>
        <path
            d="M45.3,189.7c0.9,0,1.5-0.7,1.5-1.5c0-0.9-0.7-1.5-1.5-1.5c-0.9,0-1.5,0.7-1.5,1.5C43.7,189,44.4,189.7,45.3,189.7z"/>
        <path
            d="M42.3,184.2c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2c-0.7,0-1.2,0.6-1.2,1.2C41,183.6,41.6,184.2,42.3,184.2z"/>
        <path
            d="M39.5,178.8c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2c-0.7,0-1.2,0.6-1.2,1.2C38.3,178.2,38.8,178.8,39.5,178.8z"/>
        <circle cx="37" cy="172" r="0.9"/>
        <path
            d="M34.8,167.2c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9C33.9,166.9,34.3,167.2,34.8,167.2z"/>
        <path
            d="M32.8,161.5c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C32,161.1,32.4,161.5,32.8,161.5z"/>
        <path d="M31.1,155.7c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9S30.6,155.7,31.1,155.7z"/>
        <path
            d="M29.7,149.8c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C28.9,149.4,29.3,149.8,29.7,149.8z"/>
        <path
            d="M28.6,143.8c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C27.8,143.5,28.1,143.8,28.6,143.8z"/>
        <path
            d="M27.7,137.7c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7C27,137.4,27.3,137.7,27.7,137.7z"/>
        <path
            d="M27.1,131.7c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7C26.4,131.4,26.7,131.7,27.1,131.7z"/>
        <path
            d="M26.8,125.4c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C26.4,125.2,26.6,125.4,26.8,125.4z"/>
        <path
            d="M26.8,119.3c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C26.4,119.1,26.6,119.3,26.8,119.3z"/>
        <path
            d="M27.1,113.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C26.9,113,27,113.1,27.1,113.1z"/>
        <path d="M27.7,106.9C27.7,106.9,27.8,106.9,27.7,106.9c0.1-0.1,0-0.1,0-0.1C27.7,106.8,27.6,106.8,27.7,106.9
			C27.6,106.9,27.7,106.9,27.7,106.9z"/>
        <path
            d="M252.5,215.3c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C252.3,215.2,252.4,215.3,252.5,215.3z"
        />
        <path
            d="M243.7,223.9c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C243.4,223.7,243.5,223.9,243.7,223.9z"
        />
        <path
            d="M239.1,228c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C238.5,227.7,238.7,228,239.1,228z"/>
        <path
            d="M234.2,231.3c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C234,231.2,234.1,231.3,234.2,231.3z"/>
        <path
            d="M229.3,235.4c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9C228.4,235,228.8,235.4,229.3,235.4z"/>
        <path
            d="M224.1,239c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C222.9,238.4,223.5,239,224.1,239z"/>
        <path
            d="M218.9,241.9c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2c-0.6,0-1.2,0.5-1.2,1.2C217.7,241.4,218.2,241.9,218.9,241.9z"
        />
        <path d="M213.5,245.1c0.9,0,1.6-0.7,1.6-1.6s-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6S212.6,245.1,213.5,245.1z"/>
        <circle cx="208" cy="246" r="1.4"/>
        <path
            d="M202.4,249.8c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C200.7,249.1,201.5,249.8,202.4,249.8z"
        />
        <path
            d="M196.6,251.9c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7C194.9,251.2,195.7,251.9,196.6,251.9z"
        />
        <circle cx="190.8" cy="252" r="1.7"/>
        <path
            d="M185,255.3c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C183.2,254.4,184,255.3,185,255.3z"/>
        <path
            d="M179,256.3c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C177.4,255.6,178.1,256.3,179,256.3z"/>
        <path
            d="M173.1,257.5c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C171.1,256.7,172,257.5,173.1,257.5z"/>
        <path
            d="M167.1,258.2c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C165.1,257.3,166,258.2,167.1,258.2z"/>
        <path d="M161,258.7c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C159,257.8,159.9,258.7,161,258.7z"/>
        <path d="M155,258.9c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C152.9,258,153.8,258.9,155,258.9z"/>
        <path
            d="M148.9,258.5c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C147.1,257.7,147.9,258.5,148.9,258.5z"/>
        <path
            d="M142.9,258.3c1.1,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1C140.8,257.4,141.7,258.3,142.9,258.3z"
        />
        <path
            d="M136.9,257.4c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8C135.1,256.6,135.9,257.4,136.9,257.4z"/>
        <path
            d="M130.9,256.9c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C128.7,255.9,129.6,256.9,130.9,256.9z"/>
        <path
            d="M124.9,256c1.4,0,2.5-1.1,2.5-2.5c0-1.4-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5C122.4,254.8,123.6,256,124.9,256z"/>
        <path
            d="M119.1,254.3c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4C116.7,253.3,117.8,254.3,119.1,254.3z"
        />
        <circle cx="113.3" cy="250.2" r="2.4"/>
        <path d="M107.6,250.2c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C105.5,249.3,106.4,250.2,107.6,250.2z"/>
        <circle cx="101.9" cy="246" r="2.3"/>
        <path
            d="M96.4,245.7c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C94.2,244.7,95.2,245.7,96.4,245.7z"/>
        <path d="M91,242.7c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C89.1,241.8,90,242.7,91,242.7z"/>
        <path d="M85.8,239.7c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C83.8,238.8,84.7,239.7,85.8,239.7z"/>
        <path
            d="M80.6,236.6c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c-1.2,0-2.1,0.9-2.1,2.1C78.5,235.7,79.5,236.6,80.6,236.6z"/>
        <path d="M75.7,233.2c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2S74.5,233.2,75.7,233.2z"/>
        <path
            d="M70.9,229.6c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C68.6,228.6,69.6,229.6,70.9,229.6z"/>
        <path
            d="M66.2,225.7c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C64,224.7,65,225.7,66.2,225.7z"/>
        <path
            d="M61.7,221.7c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C59.4,220.7,60.5,221.7,61.7,221.7z"/>
        <circle cx="57.5" cy="215.2" r="2.2"/>
        <path
            d="M53.4,212.9c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C51.2,211.9,52.2,212.9,53.4,212.9z"/>
        <path
            d="M49.5,208.2c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C47.3,207.2,48.3,208.2,49.5,208.2z"/>
        <path
            d="M45.8,203.5c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C43.6,202.5,44.6,203.5,45.8,203.5z"/>
        <path
            d="M42.4,197.8c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C40.8,197.1,41.5,197.8,42.4,197.8z"/>
        <path
            d="M39.2,192.7c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C37.6,192,38.3,192.7,39.2,192.7z"/>
        <path
            d="M36.2,187.3c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C34.8,186.6,35.4,187.3,36.2,187.3z"/>
        <path
            d="M33.4,181.8c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C32.1,181.2,32.7,181.8,33.4,181.8z"/>
        <path
            d="M30.9,176c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C29.8,175.5,30.3,176,30.9,176z"/>
        <path
            d="M28.7,170.5c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C27.5,169.9,28,170.5,28.7,170.5z"/>
        <path
            d="M26.7,164.7c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C25.5,164.2,26,164.7,26.7,164.7z"/>
        <path
            d="M24.9,158.1c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C24.6,158,24.8,158.1,24.9,158.1z"/>
        <circle cx="23.4" cy="151.9" r="0.4"/>
        <path
            d="M22.2,146.3c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3C22,146.2,22.1,146.3,22.2,146.3z"/>
        <path
            d="M21.3,140.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C21.1,140.1,21.2,140.2,21.3,140.2z"/>
        <path d="M20.6,134.2c0.1,0,0.1-0.1,0.1-0.1s-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1S20.5,134.2,20.6,134.2z"/>
        <path
            d="M20.2,128C20.2,128,20.3,128,20.2,128c0.1-0.1,0-0.1,0-0.1C20.2,127.9,20.1,127.9,20.2,128C20.1,128,20.2,128,20.2,128z"
        />
        <path
            d="M238.5,236.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2s-0.2,0.1-0.2,0.2C238.2,236.5,238.3,236.6,238.5,236.6z"/>
        <circle cx="233.5" cy="239.8" r="0.4"/>
        <path
            d="M228.4,243.5c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.2-0.4,0.4C228,243.3,228.2,243.5,228.4,243.5z"/>
        <path
            d="M223.1,246.4c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C222.8,246.3,222.9,246.4,223.1,246.4z"
        />
        <path d="M217.8,249.9c0.6,0,1-0.5,1-1c0-0.6-0.5-1-1-1c-0.6,0-1,0.5-1,1C216.7,249.4,217.2,249.9,217.8,249.9z"/>
        <path
            d="M212.3,251.8c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3s-0.3,0.2-0.3,0.3C212,251.6,212.1,251.8,212.3,251.8z"/>
        <path
            d="M206.7,254.8c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C205.7,254.4,206.1,254.8,206.7,254.8z"
        />
        <path
            d="M201,256.9c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C200,256.5,200.4,256.9,201,256.9z"/>
        <path
            d="M195.3,257.8c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C195.1,257.8,195.2,257.8,195.3,257.8z"
        />
        <path
            d="M189.4,259.9c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C188.9,259.6,189.1,259.9,189.4,259.9z"
        />
        <path
            d="M183.5,261.8c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1C182.4,261.3,182.9,261.8,183.5,261.8z"
        />
        <path
            d="M177.6,263.3c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C176,262.6,176.7,263.3,177.6,263.3z"/>
        <path d="M171.6,264.6c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C169.6,263.7,170.5,264.6,171.6,264.6z"/>
        <path d="M165.5,265.2c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C163.5,264.3,164.4,265.2,165.5,265.2z"/>
        <path
            d="M159.5,265.4c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C157.6,264.6,158.4,265.4,159.5,265.4z"
        />
        <path
            d="M153.4,265.5c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9C151.5,264.6,152.4,265.5,153.4,265.5z"
        />
        <path
            d="M147.4,265.5c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1c-1.2,0-2.1,0.9-2.1,2.1C145.3,264.5,146.2,265.5,147.4,265.5z"
        />
        <path
            d="M141.4,265.1c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C139.1,264.1,140.1,265.1,141.4,265.1z"/>
        <path
            d="M135.3,264.3c1.2,0,2.1-1,2.1-2.1c0-1.2-1-2.1-2.1-2.1s-2.1,1-2.1,2.1C133.2,263.4,134.2,264.3,135.3,264.3z"/>
        <path
            d="M129.4,263.3c1.1,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1C127.3,262.4,128.2,263.3,129.4,263.3z"
        />
        <path
            d="M123.4,262.3c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C121.2,261.3,122.2,262.3,123.4,262.3z"/>
        <path
            d="M117.6,260.8c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C115.3,259.8,116.3,260.8,117.6,260.8z"/>
        <path
            d="M111.8,259c1.2,0,2.1-1,2.1-2.1c0-1.2-1-2.1-2.1-2.1c-1.2,0-2.1,1-2.1,2.1C109.6,258,110.6,259,111.8,259z"/>
        <path d="M106,257c1.2,0,2.1-1,2.1-2.1s-1-2.1-2.1-2.1c-1.2,0-2.1,1-2.1,2.1S104.9,257,106,257z"/>
        <path
            d="M100.4,255c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C98.1,253.9,99.1,255,100.4,255z"/>
        <path
            d="M94.9,252.4c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C92.7,251.4,93.7,252.4,94.9,252.4z"/>
        <path
            d="M89.4,249.8c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C87.2,248.7,88.2,249.8,89.4,249.8z"/>
        <path
            d="M84.1,246.9c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C81.9,245.8,82.9,246.9,84.1,246.9z"/>
        <path
            d="M79,243.8c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C76.6,242.7,77.7,243.8,79,243.8z"/>
        <path d="M73.9,240.4c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3s-2.3,1-2.3,2.3C71.6,239.4,72.6,240.4,73.9,240.4z"/>
        <path
            d="M69,236.9c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4C66.7,235.8,67.7,236.9,69,236.9z"/>
        <path d="M64.3,232.8c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C62.3,231.9,63.2,232.8,64.3,232.8z"/>
        <path d="M59.7,229c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C57.6,228,58.5,229,59.7,229z"/>
        <path
            d="M55.3,224.9c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C53.1,223.9,54.1,224.9,55.3,224.9z"/>
        <path d="M51.1,220c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8S50.2,220,51.1,220z"/>
        <path
            d="M47.1,215.9c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C44.9,214.9,45.9,215.9,47.1,215.9z"/>
        <path
            d="M43.3,210.8c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C41.5,210,42.3,210.8,43.3,210.8z"/>
        <path d="M39.7,206c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C37.9,205.2,38.7,206,39.7,206z"/>
        <path d="M36.3,200.1c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9S35.8,200.1,36.3,200.1z"/>
        <path
            d="M33.1,195.4c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3C31.7,194.8,32.3,195.4,33.1,195.4z"/>
        <path
            d="M30.1,189.2c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4C29.7,189,29.9,189.2,30.1,189.2z"/>
        <path d="M27.3,184.2c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9S26.9,184.2,27.3,184.2z"/>
        <path d="M24.8,177.9C24.8,177.9,24.9,177.9,24.8,177.9C24.9,177.9,24.8,177.9,24.8,177.9C24.8,177.9,24.8,177.9,24.8,177.9
			C24.8,177.9,24.8,177.9,24.8,177.9z"/>
        <path d="M22.6,172.3C22.6,172.3,22.6,172.3,22.6,172.3c0.1-0.1,0-0.1,0-0.1C22.5,172.2,22.5,172.2,22.6,172.3
			C22.5,172.3,22.5,172.3,22.6,172.3z"/>
        <path
            d="M20.5,166.7c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C20.4,166.6,20.5,166.7,20.5,166.7z"/>
        <path
            d="M18.8,161.1c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C18.5,160.9,18.6,161.1,18.8,161.1z"/>
        <path d="M216.6,256.9C216.6,256.9,216.6,256.9,216.6,256.9C216.6,256.9,216.6,256.9,216.6,256.9
			C216.6,256.9,216.6,256.9,216.6,256.9C216.6,256.9,216.6,256.9,216.6,256.9z"/>
        <path d="M205.4,261.5C205.4,261.5,205.4,261.5,205.4,261.5C205.4,261.5,205.4,261.5,205.4,261.5
			C205.4,261.5,205.4,261.5,205.4,261.5C205.4,261.5,205.4,261.5,205.4,261.5z"/>
        <path
            d="M199.7,263.6c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C199.5,263.5,199.6,263.6,199.7,263.6z"
        />
        <path d="M176.1,268.9C176.1,268.9,176.1,268.8,176.1,268.9c0.1-0.1,0-0.1,0-0.1C176,268.7,176,268.8,176.1,268.9
			C176,268.8,176,268.9,176.1,268.9z"/>
        <path d="M170.1,269.6C170.1,269.6,170.1,269.6,170.1,269.6c0.1-0.1,0-0.1,0-0.1C170,269.5,170,269.5,170.1,269.6
			C170,269.6,170,269.6,170.1,269.6z"/>
        <circle cx="164" cy="270" r="0.2"/>
        <path d="M158,271c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7S157.6,271,158,271z"/>
        <circle cx="151.9" cy="270.3" r="0.7"/>
        <path
            d="M145.9,270.6c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C145.3,270.3,145.6,270.6,145.9,270.6z"
        />
        <path
            d="M139.8,270.1c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C139.3,269.9,139.5,270.1,139.8,270.1z"
        />
        <path
            d="M133.8,269.4c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C133.2,269.2,133.5,269.4,133.8,269.4z"
        />
        <path
            d="M127.9,268.6c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C127.1,268.3,127.4,268.6,127.9,268.6z"
        />
        <path
            d="M121.9,267.7c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1C120.9,267.2,121.3,267.7,121.9,267.7z"/>
        <path
            d="M116.1,265.8c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7C115.3,265.5,115.7,265.8,116.1,265.8z"/>
        <circle cx="110.3" cy="263.4" r="2.3"/>
        <path d="M104.5,263.3c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8S103.5,263.3,104.5,263.3z"/>
        <path
            d="M98.9,260.2c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9C98,259.8,98.4,260.2,98.9,260.2z"/>
        <path
            d="M93.3,259.3c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4s-2.4,1.1-2.4,2.4C90.9,258.2,92,259.3,93.3,259.3z"/>
        <path d="M87.9,256.3c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C85.9,255.4,86.8,256.3,87.9,256.3z"/>
        <path
            d="M82.5,252.1c0.4,0,0.6-0.3,0.6-0.7c0-0.4-0.3-0.7-0.6-0.7c-0.4,0-0.7,0.3-0.7,0.7C81.9,251.8,82.2,252.1,82.5,252.1z"/>
        <path
            d="M77.3,249.6c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2c-0.7,0-1.2,0.6-1.2,1.2C76.1,249.1,76.6,249.6,77.3,249.6z"/>
        <path
            d="M72.2,245.4c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3C71.9,245.3,72,245.4,72.2,245.4z"/>
        <path
            d="M67.3,243.7c1.1,0,2.1-0.9,2.1-2.1c0-1.1-0.9-2.1-2.1-2.1c-1.1,0-2.1,0.9-2.1,2.1C65.2,242.8,66.1,243.7,67.3,243.7z"/>
        <path
            d="M62.4,239.1c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2C61.3,238.6,61.8,239.1,62.4,239.1z"/>
        <path
            d="M57.8,235.4c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C56.4,234.8,57,235.4,57.8,235.4z"/>
        <path d="M53.3,232c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C51.3,231.1,52.2,232,53.3,232z"/>
        <path
            d="M49,226.3c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5C48.5,226.1,48.7,226.3,49,226.3z"/>
        <path d="M44.8,221.5c0.1,0,0.2-0.1,0.2-0.2s-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2S44.7,221.5,44.8,221.5z"/>
        <path
            d="M40.9,217.6c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8C40.1,217.2,40.4,217.6,40.9,217.6z"/>
        <path d="M37.1,212.1C37.1,212.1,37.1,212.1,37.1,212.1C37.1,212,37.1,212,37.1,212.1C37.1,212,37.1,212,37.1,212.1
			C37.1,212.1,37.1,212.1,37.1,212.1z"/>
        <path
            d="M33.5,207.4c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C33.3,207.3,33.4,207.4,33.5,207.4z"/>
        <path d="M30.1,202.2C30.2,202.2,30.2,202.2,30.1,202.2c0.1-0.1,0-0.1,0-0.1S30.1,202.1,30.1,202.2
			C30.1,202.2,30.1,202.2,30.1,202.2z"/>
        <path
            d="M27,197.1C27,197.1,27,197,27,197.1c0.1-0.1,0-0.1,0-0.1S26.9,197,27,197.1C26.9,197,26.9,197.1,27,197.1z"/>
        <path d="M18.8,180.8C18.8,180.8,18.8,180.8,18.8,180.8C18.8,180.8,18.8,180.8,18.8,180.8C18.7,180.8,18.7,180.8,18.8,180.8
			C18.7,180.8,18.7,180.8,18.8,180.8z"/>
        <path d="M108.8,270.2c0.1,0,0.2-0.1,0.2-0.2s-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2S108.6,270.2,108.8,270.2z"/>
        <path d="M103,268.2C103,268.2,103.1,268.1,103,268.2c0.1-0.1,0-0.1,0-0.1C103,268,102.9,268.1,103,268.2
			C102.9,268.1,103,268.2,103,268.2z"/>
        <path d="M97.3,266C97.4,266,97.4,266,97.3,266C97.4,266,97.4,265.9,97.3,266C97.3,265.9,97.3,266,97.3,266
			C97.3,266,97.3,266,97.3,266z"/>
        <path
            d="M91.8,263.8c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C91.6,263.7,91.7,263.8,91.8,263.8z"/>
        <path
            d="M86.3,261.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C86.1,261.1,86.2,261.2,86.3,261.2z"/>
        <path d="M80.9,258.3C80.9,258.3,80.9,258.3,80.9,258.3C80.9,258.2,80.9,258.2,80.9,258.3C80.9,258.2,80.9,258.2,80.9,258.3
			C80.9,258.3,80.9,258.3,80.9,258.3z"/>
        <path
            d="M75.7,255.4c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1C75.5,255.3,75.6,255.4,75.7,255.4z"/>
        <path d="M70.5,252.1C70.5,252.1,70.6,252.1,70.5,252.1c0.1-0.1,0-0.1,0-0.1C70.5,252,70.4,252,70.5,252.1
			C70.4,252.1,70.5,252.1,70.5,252.1z"/>
        <path d="M65.5,248.7C65.5,248.7,65.6,248.7,65.5,248.7c0.1-0.1,0-0.1,0-0.1S65.4,248.6,65.5,248.7
			C65.4,248.7,65.5,248.7,65.5,248.7z"/>
        <path d="M60.6,245.1C60.7,245.1,60.7,245.1,60.6,245.1c0.1-0.1,0-0.1,0-0.1C60.6,245,60.6,245,60.6,245.1
			C60.6,245.1,60.6,245.1,60.6,245.1z"/>
        <path d="M55.9,241.4c0.1,0,0.1-0.1,0.1-0.1s-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1S55.8,241.4,55.9,241.4z"/>
        <path d="M51.3,237.4C51.3,237.4,51.3,237.4,51.3,237.4C51.3,237.3,51.3,237.3,51.3,237.4C51.3,237.3,51.3,237.3,51.3,237.4
			C51.3,237.4,51.3,237.4,51.3,237.4z"/>
        <path d="M46.9,233.2C46.9,233.2,46.9,233.2,46.9,233.2C46.9,233.2,46.9,233.2,46.9,233.2C46.9,233.2,46.9,233.2,46.9,233.2
			C46.9,233.2,46.9,233.2,46.9,233.2z"/>
        <path d="M42.6,228.9C42.6,228.9,42.6,228.9,42.6,228.9C42.6,228.9,42.6,228.9,42.6,228.9C42.6,228.9,42.6,228.9,42.6,228.9
			C42.6,228.9,42.6,228.9,42.6,228.9z"/>
        <path d="M38.5,224.5C38.5,224.5,38.6,224.5,38.5,224.5C38.6,224.4,38.5,224.4,38.5,224.5C38.5,224.4,38.5,224.4,38.5,224.5
			C38.5,224.5,38.5,224.5,38.5,224.5z"/>
        <path d="M30.9,215.1C30.9,215.1,30.9,215.1,30.9,215.1C30.9,215,30.9,215,30.9,215.1C30.9,215,30.9,215,30.9,215.1
			C30.9,215.1,30.9,215.1,30.9,215.1z"/>
        <path d="M84.7,267.8C84.7,267.8,84.7,267.8,84.7,267.8C84.7,267.8,84.7,267.8,84.7,267.8C84.7,267.8,84.7,267.8,84.7,267.8
			C84.7,267.8,84.7,267.8,84.7,267.8z"/>
        <path d="M79.3,265.1C79.3,265.1,79.3,265.1,79.3,265.1C79.3,265,79.3,265,79.3,265.1C79.3,265,79.3,265,79.3,265.1
			C79.3,265.1,79.3,265.1,79.3,265.1z"/>
        <path d="M74,262.1C74,262.1,74,262.1,74,262.1C74,262.1,74,262.1,74,262.1C74,262.1,74,262.1,74,262.1C74,262.1,74,262.1,74,262.1
			z"/>
        <path d="M68.8,259C68.8,259,68.9,259,68.8,259C68.9,259,68.8,259,68.8,259C68.8,259,68.8,259,68.8,259C68.8,259,68.8,259,68.8,259
			z"/>
        <path d="M63.8,255.7C63.8,255.7,63.8,255.7,63.8,255.7C63.8,255.7,63.8,255.7,63.8,255.7C63.8,255.7,63.8,255.7,63.8,255.7
			C63.8,255.7,63.8,255.7,63.8,255.7z"/>
        <path d="M58.8,252.2C58.8,252.2,58.9,252.2,58.8,252.2C58.9,252.2,58.8,252.2,58.8,252.2C58.8,252.2,58.8,252.2,58.8,252.2
			C58.8,252.2,58.8,252.2,58.8,252.2z"/>
        <path d="M54,248.5C54,248.5,54.1,248.5,54,248.5C54.1,248.5,54,248.5,54,248.5C54,248.5,54,248.5,54,248.5
			C54,248.5,54,248.5,54,248.5z"/>
        <path d="M36.3,232.1C36.3,232.1,36.3,232,36.3,232.1C36.3,232,36.3,232,36.3,232.1C36.3,232,36.3,232,36.3,232.1
			C36.3,232,36.3,232.1,36.3,232.1z"/>
        <path d="M32.3,227.5C32.3,227.5,32.3,227.5,32.3,227.5C32.3,227.5,32.3,227.5,32.3,227.5C32.3,227.5,32.2,227.5,32.3,227.5
			C32.2,227.5,32.3,227.5,32.3,227.5z"/>
        <path d="M28.4,222.9C28.4,222.9,28.4,222.9,28.4,222.9C28.4,222.8,28.4,222.8,28.4,222.9C28.4,222.8,28.4,222.8,28.4,222.9
			C28.4,222.9,28.4,222.9,28.4,222.9z"/>
        <path d="M77.7,271.8C77.7,271.8,77.8,271.8,77.7,271.8C77.8,271.8,77.7,271.8,77.7,271.8C77.7,271.8,77.7,271.8,77.7,271.8
			C77.7,271.8,77.7,271.8,77.7,271.8z"/>
        <path d="M47.5,251.9C47.5,251.9,47.6,251.9,47.5,251.9c0.1-0.1,0-0.2,0-0.2C47.4,251.8,47.4,251.8,47.5,251.9
			C47.4,251.9,47.4,251.9,47.5,251.9z"/>
        <path d="M30,235.2C30,235.2,30,235.1,30,235.2C30,235.1,30,235.1,30,235.2C30,235.1,30,235.1,30,235.2C30,235.1,30,235.2,30,235.2
			z"/>
        <path d="M26,230.6C26,230.6,26,230.6,26,230.6C26,230.6,26,230.6,26,230.6C26,230.6,26,230.6,26,230.6C26,230.6,26,230.6,26,230.6
			z"/>
        <path d="M50.4,262.7C50.4,262.7,50.4,262.7,50.4,262.7C50.4,262.7,50.4,262.7,50.4,262.7C50.4,262.7,50.4,262.7,50.4,262.7
			C50.4,262.7,50.4,262.7,50.4,262.7z"/>
        <path d="M45.6,259.1C45.6,259.1,45.6,259,45.6,259.1C45.6,259,45.6,259,45.6,259.1C45.6,259,45.6,259,45.6,259.1
			C45.6,259,45.6,259.1,45.6,259.1z"/>
        <path d="M12.4,224C12.4,224,12.4,224,12.4,224C12.4,224,12.4,224,12.4,224C12.4,224,12.4,224,12.4,224C12.4,224,12.4,224,12.4,224
			z"/>
        <path
            d="M8.9,219.1C9,219.1,9,219.1,8.9,219.1C9,219,9,219,8.9,219.1C8.9,219,8.9,219,8.9,219.1C8.9,219.1,8.9,219.1,8.9,219.1z"
        />
	</g>
</g>
</svg>

    );
};

export default Background;
