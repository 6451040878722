import React from 'react';
import styled from 'styled-components'

const Container = styled.div`
    display: none;
`;

const Microdata = () => {
    return (
        <Container>
            <div itemScope itemType="https://schema.org/LocalBusiness">
                <div itemProp="name">Compass Healing, Quantum Healing Center</div>
                <div>Email: <span itemProp="email">compasshealingcenter@gmail.com</span></div>
                <div>Phone: <span itemProp="telephone">(866) 996-3760</span></div>
                <div>Url: <span itemProp="url">https://compasshealing.as.me/</span></div>
                <div itemProp="paymentAccepted">cash, check, credit card, invoice, paypal</div>
                <meta itemProp="openingHours" datetime="Mo,Tu,We,Th,Fr 08:00-17:00" />
                <div itemType="http://schema.org/GeoCoordinates" itemScope="" itemProp="geo">
                    <meta itemProp="latitude" content="37.055889" />
                    <meta itemProp="longitude" content="-113.603512" />
                </div>
                <div itemType="http://schema.org/PostalAddress" itemScope="" itemProp="address">
                    <div itemProp="streetAddress">3080 S Bloomington Dr East, </div>
                    <div><span itemProp="addressLocality">St. George </span>, <span itemProp="addressRegion">UT</span> <span itemProp="postalCode">84790</span></div>
                </div>
            </div>
        </Container>
    )
}

export default Microdata;
