import React from 'react';

const Logo = () => {
	return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.5 128.48">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_7" data-name="Layer 7">
                    <path fill="none" d="M47,39a16.86,16.86,0,0,1-5.73-9c-5.6.39-10,4.29-10,9.05s4.39,8.66,10,9"/>
                    <path fill="none" d="M47.78,46.78a9.75,9.75,0,0,0,4.59-4.58"/>
                    <path fill="none" d="M64.18,39a16.86,16.86,0,0,1,5.73,9c5.59-.39,10-4.29,10-9s-4.4-8.66-10-9A16.86,16.86,0,0,1,64.18,39Z"/>
                    <path fill="none" d="M47.78,31.22a9.75,9.75,0,0,0,4.59,4.58"/>
                    <path fill="none" d="M63.34,46.78a9.69,9.69,0,0,0-4.58-4.58"/>
                    <path fill="none" d="M55.56,47.62a16.93,16.93,0,0,1-9,5.73c.39,5.59,4.29,10,9,10s8.66-4.39,9-10"/>
                    <path fill="none" d="M58.76,35.8a9.69,9.69,0,0,0,4.58-4.58A9.78,9.78,0,0,0,58.76,35.8Z"/>
                    <path fill="#3f3f55" d="M69.91,48.05a16.86,16.86,0,0,0-5.73-9,13.84,13.84,0,0,1-5.63,2.68l-.26,0a2.21,2.21,0,0,1,0,.25l.52.22a9.69,9.69,0,0,1,4.58,4.58,12.53,12.53,0,0,1,1.31,5.64c0,.31,0,.62,0,.93-.39,5.59-4.29,10-9,10s-8.65-4.39-9-10a22.72,22.72,0,0,1-5.79.73h-.24C40.68,66.4,55.56,78,55.56,78S70.45,66.4,70.64,54.07c0-.08,0-.16,0-.24A22.2,22.2,0,0,0,69.91,48.05Z"/>
                    <path fill="#3f3f55" d="M57.77,41.83a14.39,14.39,0,0,1-4.41,0l-.52-.1-.26,0c-.06.17-.14.35-.21.52a9.75,9.75,0,0,1-4.59,4.58,12.48,12.48,0,0,1-5.63,1.3q-.48,0-.93,0c-5.6-.39-10-4.29-10-9s4.39-8.66,10-9a22.2,22.2,0,0,1-.74-5.78c0-.08,0-.16,0-.24C28.16,24.12,16.56,39,16.56,39s11.6,14.88,23.93,15.07h.24a22.72,22.72,0,0,0,5.79-.73,16.93,16.93,0,0,0,9-5.73A13.88,13.88,0,0,0,58.24,42a2.21,2.21,0,0,0,0-.25Z"/>
                    <path fill="#3f3f55" d="M70.64,23.93H70.4a22.72,22.72,0,0,0-5.79.73,17,17,0,0,0-9,5.73A13.86,13.86,0,0,0,52.89,36c.15,0,.31.11.47.15a7.53,7.53,0,0,0,4.41,0c.16,0,.32-.1.47-.15l.52-.22a9.78,9.78,0,0,1,4.58-4.58A12.55,12.55,0,0,1,69,29.92q.47,0,.93,0c5.59.39,10,4.29,10,9.05s-4.4,8.66-10,9a22.2,22.2,0,0,1,.74,5.78c0,.08,0,.16,0,.24C83,53.88,94.56,39,94.56,39S83,24.12,70.64,23.93Z"/>
                    <path fill="#ff0019" d="M52.84,36.27l-.26,0c.06.16.11.32.16.48C52.77,36.62,52.8,36.45,52.84,36.27Z"/>
                    <path fill="#92ae9d" d="M55.56,14.67c4.76,0,8.66,4.39,9,10a22.72,22.72,0,0,1,5.79-.73h.24C70.45,11.6,55.56,0,55.56,0S40.68,11.6,40.49,23.93c0,.08,0,.16,0,.24A22.2,22.2,0,0,0,41.22,30,16.86,16.86,0,0,0,47,39a13.84,13.84,0,0,0,5.63,2.68l.26,0,.52.1a14.39,14.39,0,0,0,4.41,0l.52-.1.26,0A13.84,13.84,0,0,0,64.18,39a16.86,16.86,0,0,0,5.73-9q-.47,0-.93,0a12.55,12.55,0,0,0-5.64,1.3,9.69,9.69,0,0,1-4.58,4.58l-.52.22c-.15,0-.31.11-.47.15a7.53,7.53,0,0,1-4.41,0c-.16,0-.32-.1-.47-.15l-.52-.22a9.75,9.75,0,0,1-4.59-4.58,12.66,12.66,0,0,1-1.3-5.64c0-.31,0-.62,0-.93C46.91,19.06,50.81,14.67,55.56,14.67Z"/>
                    <path fill="#3f3f55" d="M6.05,109.32a6.05,6.05,0,0,1-6-6.26v0a6.11,6.11,0,0,1,6.08-6.29,6.4,6.4,0,0,1,4.73,2l-.64.66a5.57,5.57,0,0,0-4.1-1.79A5.2,5.2,0,0,0,.94,103v0a5.25,5.25,0,0,0,5.14,5.48,5.55,5.55,0,0,0,4.19-1.9l.61.59A6.43,6.43,0,0,1,6.05,109.32Z"/>
                    <path fill="#3f3f55" d="M22.86,109.32a6.07,6.07,0,0,1-6.11-6.26v0A6.12,6.12,0,1,1,29,103s0,0,0,0A6.13,6.13,0,0,1,22.86,109.32Zm5.19-6.3a5.26,5.26,0,0,0-5.19-5.47A5.21,5.21,0,0,0,17.69,103v0a5.27,5.27,0,0,0,5.2,5.48,5.2,5.2,0,0,0,5.16-5.44Z"/>
                    <path fill="#3f3f55" d="M46.91,109.11V98.54l-4.81,7H42l-4.82-7v10.57h-.87V96.94h.86l4.88,7.2L47,96.94h.85v12.17Z"/>
                    <path fill="#3f3f55" d="M59.89,104.57H56.61v4.54h-.9V96.94h4.41c2.68,0,4.52,1.37,4.52,3.72v0C64.64,103.25,62.43,104.57,59.89,104.57Zm3.85-3.86c0-1.93-1.5-2.94-3.69-2.94H56.61v6H60c2.26,0,3.79-1.19,3.79-3Z"/>
                    <path fill="#3f3f55" d="M80.54,109.11,79,105.7H71.82l-1.55,3.41h-.94L75,96.85h.87l5.66,12.26ZM75.42,97.84l-3.22,7h6.41Z"/>
                    <path fill="#3f3f55" d="M92.24,109.28a6.89,6.89,0,0,1-4.9-2l.59-.67a5.91,5.91,0,0,0,4.36,1.8c1.83,0,3.08-1,3.08-2.41v0c0-1.28-.68-2-3.44-2.59-2.92-.59-4.1-1.58-4.1-3.42v0c0-1.8,1.65-3.19,3.91-3.19a6.07,6.07,0,0,1,4.17,1.47l-.57.72a5.27,5.27,0,0,0-3.64-1.38c-1.79,0-3,1-3,2.3v0c0,1.29.66,2.07,3.55,2.66s4,1.58,4,3.36v0C96.27,107.92,94.59,109.28,92.24,109.28Z"/>
                    <path fill="#3f3f55" d="M107.47,109.28a6.89,6.89,0,0,1-4.9-2l.59-.67a5.91,5.91,0,0,0,4.36,1.8c1.82,0,3.08-1,3.08-2.41v0c0-1.28-.68-2-3.45-2.59s-4.1-1.58-4.1-3.42v0c0-1.8,1.65-3.19,3.91-3.19a6,6,0,0,1,4.17,1.47l-.57.72a5.24,5.24,0,0,0-3.63-1.38c-1.79,0-3,1-3,2.3v0c0,1.29.66,2.07,3.54,2.66s4,1.58,4,3.36v0C111.5,107.92,109.81,109.28,107.47,109.28Z"/>
                    <path fill="#3f3f55" d="M.07,115.13H3.46V120H7.81v-4.9h3.4v13.09H7.81v-5H3.46v5H.07Z"/>
                    <path fill="#3f3f55" d="M18.45,115.13h9.84v3.08H21.81v2h5.87v2.87H21.81v2.07h6.57v3.09H18.45Z"/>
                    <path fill="#3f3f55" d="M39.42,115h3.27l5.21,13.19H44.26l-.89-2.34H38.65l-.87,2.34H34.21Zm3,8L41,119.33l-1.38,3.73Z"/>
                    <path fill="#3f3f55" d="M54.17,115.13h3.39V125h5.93v3.18H54.17Z"/>
                    <path fill="#3f3f55" d="M70.08,115.13h3.41v13.09H70.08Z"/>
                    <path fill="#3f3f55" d="M80.8,115.13H84l5,6.92v-6.92h3.36v13.09h-3L84.16,121v7.18H80.8Z"/>
                    <path fill="#3f3f55" d="M99.19,121.71v0a6.57,6.57,0,0,1,6.59-6.81,6.84,6.84,0,0,1,5,1.91l-2,2.56a4.18,4.18,0,0,0-3-1.23,3.35,3.35,0,0,0-3.13,3.59v0a3.43,3.43,0,0,0,5.28,3.09v-1.6h-2.42v-2.65h5.63v5.89a7.94,7.94,0,0,1-5.31,2A6.5,6.5,0,0,1,99.19,121.71Z"/>
                </g>
            </g>
        </svg>
	)
}

export default Logo;
