import React from 'react';
import { Helmet } from 'react-helmet';
import logo from '../images/compass-healing-logo.jpg'

function SEO({title}) {
    const description = 'Description';

    return (
        <Helmet
            htmlAttributes={{lang: 'en'}}
            title={title}
            meta={[
                {
                    name: `description`,
                    content: 'Have you been wanting to experience the Emotion and Body Code for the first time? Compass Healing provides an intro session over the phone so you can experience this very cutting edge form of healing with any of our leading Emotion and Body Code Practitioners. We are currently the highest five star rated practice in the world and look forward to working with you!!'
                },
                {
                    property: `og:title`,
                    content: 'Compass Healing Center | Leading Emotion Code, Body Code Practitioners'
                },
                {
                    property: `og:description`,
                    content: 'Have you been wanting to experience the Emotion and Body Code for the first time? Compass Healing provides an intro session over the phone so you can experience this very cutting edge form of healing with any of our leading Emotion and Body Code Practitioners. We are currently the highest five star rated practice in the world and look forward to working with you!!'
                },
                {
                    property: `og:url`,
                    content: 'http://compasshealings.com'
                },
                {
                    property: `og:site_name`,
                    content: 'Compass Healing'
                },
                {
                    property: `og:image`,
                    content: logo
                },
                {
                    property: `og:type`,
                    content: `website`
                }
            ]}
        >
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-PPTKEH3F2S"></script>
            <script>{`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'G-PPTKEH3F2S');
            `}
            </script>
        </Helmet>
    );
}

export default SEO;
