import React from 'react';

const Brandmark = () => {
	return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78 78">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_7" data-name="Layer 7">
                    <path fill="none" d="M30.38,39a16.92,16.92,0,0,1-5.73-9c-5.59.39-10,4.29-10,9.05s4.39,8.66,10,9" />
                    <path className="cls-1" fill="none" d="M31.22,46.78A9.73,9.73,0,0,0,35.8,42.2" />
                    <path className="cls-1" fill="none" d="M47.62,39a16.92,16.92,0,0,1,5.73,9c5.59-.39,10-4.29,10-9s-4.39-8.66-10-9A16.92,16.92,0,0,1,47.62,39Z" />
                    <path className="cls-1" fill="none" d="M31.22,31.22A9.73,9.73,0,0,0,35.8,35.8" />
                    <path className="cls-1" fill="none" d="M46.78,46.78A9.73,9.73,0,0,0,42.2,42.2" />
                    <path className="cls-1" fill="none" d="M39,47.62a16.92,16.92,0,0,1-9,5.73c.39,5.59,4.29,10,9.05,10s8.66-4.39,9-10" />
                    <path className="cls-1" fill="none" d="M42.2,35.8a9.73,9.73,0,0,0,4.58-4.58A9.73,9.73,0,0,0,42.2,35.8Z" />
                    <path className="cls-2" fill="#3f3f55" d="M53.35,48.05a16.92,16.92,0,0,0-5.73-9A13.88,13.88,0,0,1,42,41.68l-.25,0c0,.08,0,.17,0,.25l.52.22a9.73,9.73,0,0,1,4.58,4.58,12.52,12.52,0,0,1,1.3,5.64q0,.46,0,.93c-.39,5.59-4.29,10-9,10s-8.66-4.39-9-10a22.64,22.64,0,0,1-5.78.73h-.24C24.12,66.4,39,78,39,78S53.88,66.4,54.07,54.07a1.93,1.93,0,0,0,0-.24A22.64,22.64,0,0,0,53.35,48.05Z" />
                    <path className="cls-2" fill="#3f3f55" d="M41.2,41.83a14.32,14.32,0,0,1-4.4,0l-.53-.1-.25,0c-.07.17-.14.35-.22.52a9.73,9.73,0,0,1-4.58,4.58,12.52,12.52,0,0,1-5.64,1.3q-.46,0-.93,0c-5.59-.39-10-4.29-10-9s4.39-8.66,10-9a22.64,22.64,0,0,1-.73-5.78,1.93,1.93,0,0,1,0-.24C11.6,24.12,0,39,0,39S11.6,53.88,23.93,54.07h.24A22.64,22.64,0,0,0,30,53.35,16.92,16.92,0,0,0,39,47.62,13.88,13.88,0,0,0,41.68,42c0-.08,0-.17,0-.25Z" />
                    <path className="cls-2" fill="#3f3f55" d="M54.07,23.93h-.24a22.64,22.64,0,0,0-5.78.73,16.92,16.92,0,0,0-9,5.73A13.88,13.88,0,0,0,36.32,36l.48.15a7.5,7.5,0,0,0,4.4,0l.48-.15.52-.22a9.73,9.73,0,0,1,4.58-4.58,12.52,12.52,0,0,1,5.64-1.3q.46,0,.93,0c5.59.39,10,4.29,10,9.05s-4.39,8.66-10,9a22.64,22.64,0,0,1,.73,5.78,1.93,1.93,0,0,1,0,.24C66.4,53.88,78,39,78,39S66.4,24.12,54.07,23.93Z" />
                    <path className="cls-3" fill="#ff0019" d="M36.27,36.27l-.25,0c0,.16.11.32.15.48C36.2,36.62,36.24,36.45,36.27,36.27Z" />
                    <path className="cls-4" fill="#92ae9d" d="M39,14.67c4.76,0,8.66,4.39,9,10a22.64,22.64,0,0,1,5.78-.73h.24C53.88,11.6,39,0,39,0S24.12,11.6,23.93,23.93a1.93,1.93,0,0,0,0,.24A22.64,22.64,0,0,0,24.65,30,16.92,16.92,0,0,0,30.38,39,13.88,13.88,0,0,0,36,41.68l.25,0,.53.1a14.32,14.32,0,0,0,4.4,0l.53-.1.25,0A13.88,13.88,0,0,0,47.62,39a16.92,16.92,0,0,0,5.73-9q-.47,0-.93,0a12.52,12.52,0,0,0-5.64,1.3A9.73,9.73,0,0,1,42.2,35.8l-.52.22-.48.15a7.5,7.5,0,0,1-4.4,0L36.32,36l-.52-.22a9.73,9.73,0,0,1-4.58-4.58,12.52,12.52,0,0,1-1.3-5.64q0-.46,0-.93C30.34,19.06,34.24,14.67,39,14.67Z" />
                </g>
            </g>
        </svg>
	)
}

export default Brandmark;
